<template lang="pug">
.address-booking-step
  .row
    .col-12
      h4 {{ $t('mainTraveler') }}
  address-details(
    :booking-ticket="bookingTicket"
    :address="address"
    :address-countries="addressCountries"
    :change-address="changeAddress"
  )

</template>

<script>
import AddressDetails from "@/views/addresses/AddressDetails.vue";

export default {
  name: "AddressBookingStep",
  components: { AddressDetails },
  props: {
    bookingTicket: {
      type: Object,
      required: false,
    },
    address: {
      type: Object,
      required: false,
    },
    addressCountries: {
      type: Array,
      required: true,
    },
    changeAddress: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped></style>
