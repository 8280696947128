const validator = require("email-validator");

class EmailValidatorComponent {
  validateEmail(email) {
    try {
      return validator.validate(email?.toString()?.trim());
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

module.exports = EmailValidatorComponent;
