import gql from "graphql-tag";

export const HyperGuest = {
    Queries: {
        Rate: gql`
        query rate($bookingDate: Date, $bookingTicketId: ID, $bookingTicketVoucherId: ID) {
            rate(bookingDate: $bookingDate, bookingTicketId: $bookingTicketId, bookingTicketVoucherId: $bookingTicketVoucherId)
        }`,
    }
};
