<template lang="pug">
.fellow-traveler-editing
  .row
    .col-md-3.col-sm-12
      .form-group
        label.form-label {{ $t('firstName') }}
        input.form-control(type="text" v-model="fellowTraveler.FirstName")
    .col-md-3.col-sm-12
      .form-group
        label.form-label {{ $t('lastName') }}
        input.form-control(type="text" v-model="fellowTraveler.LastName")
    .col-md-3.col-sm-12
      .form-group
        label.form-label {{ $t('birthDate') }}
        input.form-control(type="date" :value="fellowTraveler.BirthDate | formatInputDate" @change="fellowTraveler.BirthDate = $event.target.value;")
    .col-md-3.col-sm-12
      .form-group
        label.form-label {{ $t('nationality') }}
        select.form-control(v-model="fellowTraveler.Country" v-if="addressCountries && Array.isArray(addressCountries) && addressCountries.length")
          option(:value="addressCountry.ISO31661alpha2" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}

</template>

<script>
export default {
  name: "FellowTravelerEditing",
  props: {
    fellowTraveler: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    addressCountries: {
      type: Array,
      required: false,
    },
  },
};
</script>

<style scoped></style>
