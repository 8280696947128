import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export class UtilsComponent {
  getIsSameArrivalDayAvailable(hosts, products) {
    try {
      if (hosts?.length > 1) {
        return {
          IsSuccess: false,
          Message: "isSameArrivalDayAvailable.1",
        };
      }
      const isThirdPartyBooking = this.getIsThirdPartyBooking(hosts);
      if (!isThirdPartyBooking) {
        return {
          IsSuccess: true,
        };
      }
      if (products.length > 1) {
        return {
          IsSuccess: false,
          Message: "isSameArrivalDayAvailable.2",
        };
      }
      return {
        IsSuccess: true,
      };
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }

  getIsCombineTravelPeriodAvailable(bookingTicketVouchers, hosts) {
    try {
      if (bookingTicketVouchers?.length <= 1) {
        return {
          IsSuccess: false,
          Message: "isCombineTravelPeriodAvailable.1",
        };
      }
      if (hosts?.length > 1) {
        return {
          IsSuccess: false,
          Message: "isCombineTravelPeriodAvailable.2",
        };
      }
      return {
        IsSuccess: true,
      };
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
      };
    }
  }

  getAreDifferentArrivalDaysAvailable(bookingTicketVouchers, hosts) {
    try {
      if (bookingTicketVouchers?.length <= 1) {
        return {
          IsSuccess: false,
          Message: "areDifferentArrivalDaysAvailable.1",
        };
      }
      if (
        hosts?.length > 1 &&
        !hosts?.some(
          (host) =>
            host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.HyperGuest,
        )
      ) {
        return {
          IsSuccess: false,
          Message: "areDifferentArrivalDaysAvailable.2",
        };
      }
      return {
        IsSuccess: true,
      };
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }

  getAreMultipleArrivalDaysAvailable(hosts) {
    try {
      if (
        hosts?.every(
          (host) =>
            host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.HyperGuest,
        )
      ) {
        return false;
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  getIsThirdPartyBooking(hosts) {
    try {
      if (!hosts) {
        return false;
      }
      return hosts.some(
        (host) =>
          host.ChannelPropertyTypeId &&
          host.ChannelPropertyTypeId != ChannelPropertyTypesEnum.TouriBook,
      );
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  getIsBindingBookingAvailable(bookingTicket) {
    try {
      if (!bookingTicket) {
        return false;
      }
      if (
        bookingTicket.BookingTicketTypeId ==
        BookingTicketTypesEnum.DifferentArrivalDays
      ) {
        return false;
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
