<template lang="pug">
.booking-dates-booking-step
  template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
    .row(v-for="bookingTicketVoucher in bookingTicketVouchers")
      .col-12
        .booking-date-selection
          .card
            .card-header
              .row
                .col-12
                  .float-left
                    h5.font-weight-bold {{ bookingTicketVoucher.VoucherId }}
                  .float-right
                    h5.font-weight-bold {{ $t("bookingTicketVoucherStatus." + bookingTicketVoucher.BookingTicketVoucherStatusId) }}
            .card-body.m-2
              .row
                .col-md-6.col-sm-12
                  booking-date-picker(
                    :booking-ticket="bookingTicket"
                    :booking-ticket-voucher="bookingTicketVoucher"
                    :booking-date-requests="bookingDateRequests"
                    :select-date="selectDate"
                    :is-disabled="!bookingDateRequest"
                  )
                .col-md-6.col-sm-12.mt-md-0.mt-sm-3
                  booking-date-requests-editing(
                    :booking-date-requests="bookingDateRequests"
                    :booking-ticket="bookingTicket"
                    :booking-ticket-vouchers="bookingTicketVouchers"
                    :booking-ticket-voucher="bookingTicketVoucher"
                    :selected-booking-date-request="bookingDateRequest"
                    :nights="nights"
                    :select-booking-date-request="selectBookingDateRequest"
                    :add-booking-date-request="addBookingDateRequest"
                    :delete-booking-date-request="deleteBookingDateRequest"
                    :is-disabled="!bookingDateRequest"
                )

  .row(v-else)
    .col-md-6.col-sm-12
      booking-date-picker(
        :booking-ticket="bookingTicket"
        :booking-date-requests="bookingDateRequests"
        :nights="nights"
        :select-date="selectDate"
        :is-disabled="!bookingDateRequest"
      )
    .col-md-6.col-sm-12.mt-md-0.mt-sm-3
      booking-date-requests-editing(
        :booking-date-requests="bookingDateRequests"
        :booking-ticket="bookingTicket"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :nights="nights"
        :hosts="hosts"
        :selected-booking-date-request="bookingDateRequest"
        :select-booking-date-request="selectBookingDateRequest"
        :add-booking-date-request="addBookingDateRequest"
        :delete-booking-date-request="deleteBookingDateRequest"
      )


  hyper-guest-rate.mt-3(
    v-if="rate"
    :rate="rate"
  )

</template>

<script>
import BookingDatePicker from "@/views/components/datepicker/BookingDatePicker.vue";
import BookingDateRequestsEditing from "@/views/bookingDateRequests/BookingDateRequestsEditing.vue";
import HyperGuestRate from "@/views/hyperGuest/HyperGuestRate.vue";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export default {
  name: "BookingDatesBookingStep",
  components: { HyperGuestRate, BookingDateRequestsEditing, BookingDatePicker },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVouchers: {
      type: Array,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    bookingDateRequest: {
      type: Object,
      required: false,
    },
    nights: {
      type: Array,
      required: true,
    },
    hosts: {
      type: Array,
      required: true,
    },
    selectDate: {
      type: Function,
      required: true,
    },
    selectBookingDateRequest: {
      type: Function,
      required: true,
    },
    addBookingDateRequest: {
      type: Function,
      required: true,
    },
    deleteBookingDateRequest: {
      type: Function,
      required: true,
    },
    rate: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
};
</script>

<style scoped></style>
