const {
  AddressCountries,
} = require("@/graphql/addressCountries/AddressCountries.ts");

class AddressCountriesComponent {
  async getAddressCountries(apollo) {
    try {
      const addressCountries = await apollo
        .query({
          query: AddressCountries.Queries.AddressCountries,
        })
        .then(({ data }) => data?.addressCountries)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return addressCountries;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = AddressCountriesComponent;
