<template lang="pug">
.login-wrapper
  .login-position
    .container-fluid
      .row
        .col-12.col-md-10.offset-md-1.col-lg-8.offset-lg-2.col-xl-6.offset-xl-3.bg-white.px-4.py-5
          .row
            .col-12.text-center.mb-4
              img.login-logo(:src="`${publicPath}images/touribook-grey-colored-basic-250.png`" alt="")
            .col-12.text-center.mb-3
              h3.txt-light {{$t("login.headline")}}
          .row.text-center(style="font-weight: 700").mb-4
            .col-xs-12.col-sm-4
              span.align-self-center
                img(:src="`${publicPath}images/tbook-check.png`")
                | {{ $t("login.check1") }}
            .col-xs-12.col-sm-4
              span
                img(:src="`${publicPath}images/tbook-check.png`")
                | {{ $t("login.check2") }}
            .col-xs-12.col-sm-4
              span
                img(:src="`${publicPath}images/tbook-check.png`")
                | {{ $t("login.check3") }}
          hr

          .mt-4
            .row(:class="{ 'd-none': !isVoucherLogin }")
              .col-xs-12.col-md-6
                .form-group
                  label(for="inputVoucherNo", style="font-weight: 700") {{ $t("login.vouchernumber") }}
                  input#inputVoucherNo.form-control(type="text" v-model="voucherId" :placeholder="$t('login.vouchernumber-placeholder')")
              .col-xs-12.col-md-6
                .form-group
                  label(style="font-weight: 700") {{ $t("login.bookingcode") }}
                  input.form-control(type="password" v-model="code" :placeholder="$t('login.bookingcode-placeholder')")
            .row(:class="{ 'd-none': isVoucherLogin }")
              .col-xs-12.col-md-6
                .form-group
                  label(style="font-weight: 700") {{ $t("login.email") }}
                  input.form-control(type="email" v-model="email" :placeholder="$t('login.email')")
              .col-xs-12.col-md-6
                .form-group
                  label(style="font-weight: 700") {{ $t("login.password") }}
                  input.form-control(type="password" v-model="password" :placeholder="$t('login.password')")

          .row
            .col-12
              .form-group.form-check.text-muted
                input.form-check-input(type="checkbox" id="acceptedTerms" v-model="acceptedTerms")
                label.form-check-label(for="acceptedTerms") {{ $t("login.permission1") }}
                  a(href="https://www.touridat.com/agb") {{ $t("login.permission2") }}
                  | {{ $t("login.permission3") }}
                  a(href="https://www.touridat.com/datenschutz") {{ $t("login.permission4") }}
                  | {{ $t("login.permission5") }}

          .row
            .col-12
              .float-right.d-flex
                button.button.button-secondary.button-tbook.mr-2(@click="changeLogin")
                  template(v-if="isVoucherLogin")
                    font-awesome-icon.mr-2(:icon="['fas', 'user']")
                    span {{ $t("login.changeLogin.user") }}
                  template(v-else)
                    font-awesome-icon.mr-2(:icon="['fas', 'file-lines']")
                    span {{ $t("login.changeLogin.voucher") }}
                button.button.button-primary.button-tbook(type="submit" @click="login")
                  font-awesome-icon.mr-2(:icon="['fas', 'right-to-bracket']")
                  span {{ $t("buttons.login") }}

          hr
          .row
            .col-12.text-center
              p.mt-3.mb-3.text-muted &copy; 2021 | touriDat GmbH & Co. KG |&nbsp;
                a(href="https://www.touridat.com/impressum") Impressum
            .col-12.text-center
              img(:src="`${publicPath}images/flag_ger.jpg`", style="height: 20px; cursor: pointer;", @click="changeLanguage('de')").pr-2
              img(:src="`${publicPath}images/flag_gb.jpg`", style="height: 20px; cursor: pointer;", @click="changeLanguage('en')").pl-2

</template>

<script>
import EventBus from "@/event-bus";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import MolossUsersComponent from "@/components/molossUsers/MolossUsersComponent";
import AddressesComponent from "@/components/addresses/AddressesComponents";
import EmailValidatorComponent from "@/components/emailValidator/EmailValidatorComponent";

const moment = require("moment");

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data() {
    return {
      isVoucherLogin: true,
      voucherId: "",
      code: "",
      email: "",
      password: "",
      acceptedTerms: false,
      publicPath: process.env.BASE_URL,
    };
  },
  async mounted() {
    try {
      if (!this.$route.query.auth) {
        return;
      }
      const vouchersComponent = new VouchersComponent();
      const voucher =
        await vouchersComponent.getVoucherByVoucherAuthentificationUrl(
          this.$apollo,
          this.$route.query.auth,
        );
      if (!voucher) {
        return;
      }
      this.$session.start();
      this.$session.set("voucherId", voucher.Id);
      this.$session.set("validTill", moment().add(12, "hours").toDate());
      this.$router.push({ name: "Home" });
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    changeLogin() {
      try {
        this.isVoucherLogin = !this.isVoucherLogin;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async login() {
      try {
        if (!this.acceptedTerms) {
          return this.$alert(this.$t("login.alerts.read-gtc").toString());
        }
        if (this.isVoucherLogin) {
          const loggedInVoucher = await this._loginVoucher();
          if (!loggedInVoucher.IsSuccess) {
            return this.$alert(loggedInVoucher.Message);
          }
          if (!this.$session.exists()) {
            this.$session.start();
          }
          const addressesComponent = new AddressesComponent();
          EventBus.$emit("changeLoadingState", true);
          const addresses = await addressesComponent.getAddresses(
            this.$apollo,
            undefined,
            this.voucherId,
          );
          EventBus.$emit("changeLoadingState", false);
          if (!addresses || !Array.isArray(addresses)) {
            return this.$alert(
              `Es konnten keine Buchungsadressen für den Gutschein ${this.voucherId} geladen werden.`,
            );
          }
          if (addresses.length) {
            let email = await this.$prompt(this.$t("enterConfirmationEmail"))
              .then((result) => result)
              .catch((e) => e);
            if (email) {
              email = email.trim().toLowerCase();
              const emailValidatorComponent = new EmailValidatorComponent();
              const isEmailValid = emailValidatorComponent.validateEmail(email);
              if (!isEmailValid) {
                return this.$alert(this.$t("enterValidEmail"));
              }
              if (addresses.some((address) => address.Email == email)) {
                this.$session.remove("voucherId");
                this.$session.set("email", email);
                return await this.$router.push({ name: "Home" });
              }
            }
          }
          this.$session.remove("email");
          this.$session.set("voucherId", loggedInVoucher.StringData);
        } else {
          const loggedInUser = await this._loginUser();
          if (!loggedInUser.IsSuccess) {
            return this.$alert(loggedInUser.Message);
          }
          if (!this.$session.exists()) {
            this.$session.start();
          }
          this.$session.remove("voucherId");
          this.$session.set("email", this.email.toLowerCase().trim());
        }
        this.$session.set("validTill", moment().add(12, "hours").toDate());
        return await this.$router.push({ name: "Home" });
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _loginVoucher() {
      try {
        const vouchersComponent = new VouchersComponent();
        EventBus.$emit("changeLoadingState", true);
        const loggedInVoucher = await vouchersComponent.loginVoucher(
          this.$apollo,
          this.voucherId,
          this.code,
        );
        EventBus.$emit("changeLoadingState", false);
        if (!loggedInVoucher) {
          return this.$alert("Der Gutschein konnte nicht eingeloggt werden.");
        }
        return loggedInVoucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _loginUser() {
      try {
        const molossUsersComponent = new MolossUsersComponent();
        EventBus.$emit("changeLoadingState", true);
        const loggedInMolossUser = await molossUsersComponent.loginMolossUser(
          this.$apollo,
          this.email,
          this.password,
        );
        EventBus.$emit("changeLoadingState", false);
        if (!loggedInMolossUser) {
          return this.$alert("Der Benutzer konnte nicht eingeloggt werden.");
        }
        return loggedInMolossUser;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    changeLanguage(lang) {
      try {
        this.$i18n.locale = lang;
        localStorage.setItem("localeSettings", lang);
      } catch (e) {
        localStorage.setItem("localeSettings", "de");
      }
    },
  },
};
</script>

<style scoped lang="scss">
html {
  height: 100%;
}

#login {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;

    &:focus {
      z-index: 2;
    }
  }

  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
