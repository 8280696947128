<template lang="pug">
.booking-ticket-host
  .row
    .col-12
      h5 {{ host.Match1 }}
  template(v-for="bookingTicketVoucher in bookingTicketVouchers")
    .row
      .col-12
        voucher(
          :voucher-id="bookingTicketVoucher.VoucherId"
          :host="host"
          :is-preview="true"
        )
    template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
      .row
        .col-12
          h5 {{ $t('bookingPeriods') }}:
      booking-date-requests(
        :booking-date-requests="bookingDateRequests"
        :booking-ticket="bookingTicket"
        :booking-ticket-voucher="bookingTicketVoucher"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :check-booking-date-request="checkBookingDateRequest"
        :is-edit="isEdit"
      )
      fellow-travelers.mt-3(
        :booking-ticket="bookingTicket"
        :booking-ticket-voucher="bookingTicketVoucher"
      )

</template>

<script>
import Voucher from "@/views/vouchers/Voucher.vue";
import BookingTicketVouchersComponent from "@/components/bookingTicketVouchers/BookingTicketVouchersComponent";
import FellowTravelers from "@/views/fellowTravelers/FellowTravelers.vue";
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export default {
  name: "BookingTicketHost",
  components: { BookingDateRequests, FellowTravelers, Voucher },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    checkBookingDateRequest: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      bookingTicketVouchers: [],
      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
  async mounted() {
    try {
      this.loadBookingTicketVouchers();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadBookingTicketVouchers() {
      try {
        const bookingTicketVouchersComponent =
          new BookingTicketVouchersComponent();
        const bookingTicketVouchers =
          await bookingTicketVouchersComponent.getBookingTicketVouchers(
            this.$apollo,
            this.bookingTicket?.Id,
            [this.host.Id],
          );
        if (!bookingTicketVouchers) {
          return;
        }
        this.bookingTicketVouchers = bookingTicketVouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
