import gql from "graphql-tag";

export const MolossUsers = {
    Mutations: {
        LoginMolossUser: gql`
        mutation loginMolossUser($email: String, $password: String) {
            loginMolossUser(email: $email, password: $password) {
                IsSuccess
            }
        }`,
    },
};
