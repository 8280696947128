<template lang="pug">
.booking-date-requests(v-if="bookingDateRequests && Array.isArray(bookingDateRequests) && bookingDateRequests.length")
  booking-date-request(
    v-for="bookingDateRequest in localBookingDateRequests"
    :booking-date-request="bookingDateRequest"
    :booking-ticket="bookingTicket"
    :booking-ticket-voucher="bookingTicketVoucher"
    :booking-ticket-vouchers="bookingTicketVouchers"
    :check-booking-date-request="checkBookingDateRequest"
    :is-edit="isEdit"
    :key="bookingDateRequests.indexOf(bookingDateRequest)"
    :class="{ 'mt-1': bookingDateRequests.indexOf(bookingDateRequest) > 0 }"
  )

</template>

<script>
import BookingDateRequest from "@/views/bookingDateRequests/BookingDateRequest.vue";

export default {
  name: "BookingDateRequests",
  components: { BookingDateRequest },
  props: {
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingTicketVouchers: {
      type: Array,
      required: false,
    },
    checkBookingDateRequest: {
      type: Function,
      required: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localBookingDateRequests: this.getBookingDateRequests(),
    };
  },
  methods: {
    getBookingDateRequests() {
      try {
        return this.bookingDateRequests?.filter(
          (bookingDateRequest) =>
            bookingDateRequest.BookingTicketVoucherId ==
            this.bookingTicketVoucher?.Id,
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    bookingDateRequests() {
      try {
        this.localBookingDateRequests = this.getBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
