<template lang="pug">
.booking-ticket-voucher
  voucher(:voucher="voucher" v-if="voucher")
  template(v-if="localBookingDateRequests && Array.isArray(localBookingDateRequests) && localBookingDateRequests.length")
    .row
      .col-12
        h4 Anreisetage
    .row(v-for="bookingDateRequest in localBookingDateRequests.filter((b) => b.Date)" v-if="product")
      .col-12
        span {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(product.Nights, 'days').toDate() | formatDateWithDay($t("lang")) }}

</template>

<script>
import Voucher from "@/views/vouchers/Voucher.vue";
import BookingDateRequestsComponent from "@/components/bookingDateRequests/BookingDateRequestsComponent";
import BookingDatePicker from "@/views/components/datepicker/BookingDatePicker.vue";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import ProductsComponent from "@/components/products/ProductsComponent";
import moment from "moment";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export default {
  name: "BookingTicketVoucher",
  components: { BookingDatePicker, Voucher },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: true,
    },
    bookingDateRequests: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      localBookingDateRequests: this.bookingDateRequests,
      voucher: undefined,
      product: undefined,
      moment: moment,
      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
  async mounted() {
    try {
      if (!this.bookingDateRequests) {
        this.loadBookingDateRequests();
      }
      this.loadVoucher();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVoucher() {
      try {
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.bookingTicketVoucher?.VoucherId,
        );
        if (!voucher) {
          return;
        }
        this.voucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.voucher?.ProductId,
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingDateRequests() {
      try {
        const bookingDateRequestsComponent = new BookingDateRequestsComponent();
        const bookingDateRequests =
          await bookingDateRequestsComponent.getBookingDateRequests(
            this.$apollo,
            undefined,
            this.bookingTicketVoucher.Id,
          );
        if (
          !bookingDateRequests ||
          !Array.isArray(bookingDateRequests) ||
          !bookingDateRequests.length
        ) {
          return;
        }
        this.localBookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    bookingDateRequests() {
      try {
        if (
          !this.bookingDateRequests ||
          !Array.isArray(this.bookingDateRequests) ||
          !this.bookingDateRequests.length
        ) {
          return;
        }
        this.localBookingDateRequests = this.bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    voucher() {
      try {
        if (!this.voucher?.Id) {
          return;
        }
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
