<template lang="pug">
.fellow-travelers(v-if="fellowTravelers && Array.isArray(fellowTravelers) && fellowTravelers.length")
  .row
    .col-12
      h5 {{ $t('fellowTravelers') }}:
  fellow-traveler(
    v-for="fellowTraveler in fellowTravelers"
    :fellow-traveler="fellowTraveler"
    :key="fellowTravelers.indexOf(fellowTraveler)"
  )

</template>

<script>
import FellowTravelersComponent from "@/components/fellowTravelers/FellowTravelersComponent";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import FellowTraveler from "@/views/fellowTravelers/FellowTraveler.vue";

export default {
  name: "FellowTravelers",
  components: { FellowTraveler },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      fellowTravelers: [],
    };
  },
  async mounted() {
    try {
      this.loadFellowTravelers();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadFellowTravelers() {
      try {
        let fellowTravelers = [];
        const fellowTravelersComponent = new FellowTravelersComponent();
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          fellowTravelers = await fellowTravelersComponent.getFellowTravelers(
            this.$apollo,
            undefined,
            this.bookingTicketVoucher?.Id,
          );
        } else {
          fellowTravelers = await fellowTravelersComponent.getFellowTravelers(
            this.$apollo,
            this.bookingTicket?.Id,
          );
        }
        if (!fellowTravelers) {
          return;
        }
        this.fellowTravelers = fellowTravelers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    "bookingTicket.BookingTicketTypeId"() {
      try {
        this.loadFellowTravelers();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
