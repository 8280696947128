import moment from "moment";

let VueFilter = {
  formatDateWithDayFilter: {
    name: "formatDateWithDay",
    func: (value, lang = "de") => {
      if (!value) {
        return undefined;
      }
      const date = moment(value).locale(lang);
      return date.format("dd") + ". " + date.format("DD.MM.YYYY");
    },
  },
  formatInputDateFilter: {
    name: "formatInputDate",
    func: (value) => {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  formatInputDateTimeFilter: {
    name: "formatInputDateTime",
    func: (value) => {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
  },
  formatDateFilter: {
    name: "formatDate",
    func: (value, lang = "de") => {
      if (!value) {
        return undefined;
      }
      return moment(value).locale(lang).format("L");
    },
  },
  formatDateTimeFilter: {
    name: "formatDateTime",
    func: (value, lang = "de") => {
      if (!value) {
        return undefined;
      }
      value = moment(value).locale(lang);
      return `${value.format("L")} ${value.format("LTS")}`;
    },
  },
  formatEuroFilter: {
    name: "formatEuro",
    func: (value) => {
      if (!value) {
        return "0,00 €";
      }
      return `${parseFloat(value).toFixed(2).toString().replace(".", ",")} €`;
    },
  },
  formatValueFilter: {
    name: "formatValue",
    func: (value) => {
      if (!value) {
        return "0,00";
      }
      return `${parseFloat(value).toFixed(2).toString().replace(".", ",")}`;
    },
  },
  formatPercentageFilter: {
    name: "formatPercentage",
    func: (value) => {
      if (!value) {
        return "0,00 %";
      }
      return `${parseFloat(value).toFixed(2).toString().replace(".", ",")} %`;
    },
  },
};
export default VueFilter;
