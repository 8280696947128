<template lang="pug">
  .form-group
    label.form-label {{ $t('vouchers') }}
    select.form-control(v-model="currentVoucher")
      option(:value="undefined") {{ $t('yourBoughtVouchers') }}
      host-vouchers-option-group(
        v-for="host in hosts"
        :host="host"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :change-voucher="changeVoucher"
      )

</template>

<script>
import HostVouchersOptionGroup from "@/views/components/select/options/HostVouchersOptionGroup.vue";

export default {
  name: "HostVouchersSelect",
  components: { HostVouchersOptionGroup },
  props: {
    hosts: {
      type: Array,
      required: false,
    },
    voucher: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    bookingTicketVouchers: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    changeVoucher: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentVoucher: undefined,
    };
  },
  watch: {
    currentVoucher() {
      try {
        this.changeVoucher(this.currentVoucher);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
