const {
  BookingTicketVouchers,
} = require("@/graphql/bookingTicketVouchers/BookingTicketVouchers.ts");

class BookingTicketVouchersComponent {
  async getBookingTicketVouchers(apollo, bookingTicketId, hostIds) {
    try {
      if (!apollo || (!bookingTicketId && !hostIds)) {
        return undefined;
      }
      const bookingTicketVouchers = await apollo
        .query({
          query: BookingTicketVouchers.Queries.BookingTicketVouchers,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicketId,
            hostIds: hostIds,
          },
        })
        .then(({ data }) => data?.bookingTicketVouchers)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return bookingTicketVouchers;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async deleteBookingTicketVouchers(apollo, id) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!id) {
        return {
          IsSuccess: false,
          Message: "Kein Gutschein gefunden.",
        };
      }
      const deletedBookingTicketVoucher = await apollo
        .mutate({
          mutation: BookingTicketVouchers.Mutations.DeleteBookingTicketVoucher,
          fetchPolicy: "no-cache",
          variables: {
            id: id,
          },
        })
        .then(({ data }) => data?.deleteBookingTicketVoucher)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return deletedBookingTicketVoucher;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = BookingTicketVouchersComponent;
