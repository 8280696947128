<template lang="pug">
.booking-ticket-overview
  booking-ticket-host.mb-3(
    v-for="host in hosts"
    :booking-ticket="bookingTicket"
    :host="host"
    :booking-date-requests="bookingDateRequests"
    :check-booking-date-request="checkBookingDateRequest"
    :is-edit="isEdit"
  )

  template(v-if="bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.DifferentArrivalDays")
    .mb-3(v-if="bookingDateRequests && Array.isArray(bookingDateRequests) && bookingDateRequests.length")
      .row
        .col-12
          h5 {{ $t('bookingPeriods') }}:
      booking-date-requests(
        :booking-date-requests="bookingDateRequests"
        :booking-ticket="bookingTicket"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :check-booking-date-request="checkBookingDateRequest"
        :is-edit="isEdit"
      )
  .mb-3(v-if="addressString")
    .row
      .col-12
        h5 {{ $t('mainTraveler') }}:
    .row
      .col-12
        span {{ addressString }}
        span(v-if="address.Country") &nbsp;{{ address.Country }}
        span(v-if="address.BirthDate") ,&nbsp;{{ address.BirthDate | formatDate($t('lang')) }}
        span(v-if="address.Phone") ,&nbsp;{{ address.Phone }}
  fellow-travelers.mt-3(
    :booking-ticket="bookingTicket"
  )

  .row(v-if="isEdit")
    .col-12
      .float-right
        button.button.button-primary.button-tdays(
          v-if="isSaveBookingTicketAvailable"
          @click="saveBookingTicket"
        )
          font-awesome-icon.mr-2(:icon="['fas', 'floppy-disk']")
          span {{ $t("saveBooking") }}

        button.button.button-secondary.button-tpics(
          v-if="isBookingTicketCancelRequestAllowed"
          @click="requestBookingTicketCancel"
        )
          font-awesome-icon.mr-2(:icon="['fas', 'ban']")
          span {{ $t("requestCancel") }}

</template>

<script>
import AddressesComponent from "@/components/addresses/AddressesComponents";
import BookingTicketVouchersComponent from "@/components/bookingTicketVouchers/BookingTicketVouchersComponent";
import Voucher from "@/views/vouchers/Voucher.vue";
import BookingDateRequests from "@/views/bookingDateRequests/BookingDateRequests.vue";
import FellowTravelers from "@/views/fellowTravelers/FellowTravelers.vue";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import BookingDateRequestsComponent from "@/components/bookingDateRequests/BookingDateRequestsComponent";
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { BookingTicketVoucherStatusEnum } from "@/utils/enums/bookingTicketVoucherStatus/BookingTicketVoucherStatusEnum.ts";
import { BookingTicketsComponent } from "@/components/bookingTickets/BookingTicketsComponent";
import EventBus from "@/event-bus";
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import HostsComponent from "@/components/hosts/HostsComponent";
import BookingTicketHost from "@/views/hosts/BookingTicketHost.vue";

export default {
  name: "BookingTicketOverview",
  components: {
    BookingTicketHost,
    FellowTravelers,
    BookingDateRequests,
    Voucher,
  },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadBookingTicket: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      address: undefined,
      bookingTicketVouchers: [],
      bookingDateRequests: [],
      hosts: [],
      BookingTicketTypesEnum: BookingTicketTypesEnum,
      BookingTicketVoucherStatusEnum: BookingTicketVoucherStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadAddress();
      this.loadBookingTicketVouchers();
      this.loadBookingDateRequests();
      this.loadHosts();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadAddress() {
      try {
        const addressesComponent = new AddressesComponent();
        const address = await addressesComponent.getAddress(
          this.$apollo,
          this.bookingTicket.AddressId,
        );
        if (!address) {
          return;
        }
        this.address = address;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingTicketVouchers() {
      try {
        const bookingTicketVouchersComponent =
          new BookingTicketVouchersComponent();
        const bookingTicketVouchers =
          await bookingTicketVouchersComponent.getBookingTicketVouchers(
            this.$apollo,
            this.bookingTicket?.Id,
          );
        if (!bookingTicketVouchers) {
          return;
        }
        this.bookingTicketVouchers = bookingTicketVouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingDateRequests() {
      try {
        const bookingDateRequestsComponent = new BookingDateRequestsComponent();
        const bookingDateRequests =
          await bookingDateRequestsComponent.getBookingDateRequests(
            this.$apollo,
            this.bookingTicket?.Id,
          );
        this.bookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHosts() {
      try {
        const hostsComponent = new HostsComponent();
        const hosts = await hostsComponent.getHosts(
          this.$apollo,
          this.bookingTicket?.Id,
        );
        if (!hosts) {
          return;
        }
        this.hosts = hosts;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveBookingTicket() {
      try {
        if (
          this.bookingDateRequests.every(
            (bookingDateRequest) =>
              bookingDateRequest.BookingDateRequestStatusId ==
              BookingDateRequestStatusEnum.DateCanBeBooked,
          )
        ) {
          return this.$alert(
            "Bitte wählen Sie für den Anreisetag einen Status aus.",
          );
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedBookingTicket =
          await bookingTicketsComponent.saveBookingTicket(
            this.$apollo,
            this.bookingTicket,
            this.bookingTicketVouchers,
            this.bookingDateRequests,
          );
        EventBus.$emit("changeLoadingState", false);
        if (!savedBookingTicket) {
          return this.$alert("Die Buchung konnte nicht gespeichert werden.");
        }
        if (savedBookingTicket.Message) {
          this.$alert(savedBookingTicket.Message);
        }
        if (savedBookingTicket.IsSuccess) {
          if (this.loadBookingTicket) {
            return this.loadBookingTicket();
          }
          this.$router
            .push({
              name: "BookingTicketDetails",
              params: { bookingTicketGuid: savedBookingTicket.Guid },
            })
            .catch(() => {});
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async checkBookingDateRequest(
      bookingDateRequest,
      isAccepted,
      bookingTicketVoucher = undefined,
    ) {
      try {
        if (!bookingDateRequest) {
          return;
        }
        let bookingDateRequests = this.bookingDateRequests;
        if (bookingTicketVoucher) {
          bookingDateRequests = bookingDateRequests.filter(
            (bookingDateRequest) =>
              bookingDateRequest.BookingTicketVoucherId ==
              bookingTicketVoucher.Id,
          );
        }
        // SET EVERY BOOKED AND CAN BE BOOKED DATE ON REJECTED
        bookingDateRequests
          .filter(
            (bookingDateRequest) =>
              bookingDateRequest.BookingDateRequestStatusId ==
                BookingDateRequestStatusEnum.DateBooked ||
              bookingDateRequest.BookingDateRequestStatusId ==
                BookingDateRequestStatusEnum.DateCanBeBooked,
          )
          .forEach(
            (bookingDateRequest) =>
              (bookingDateRequest.BookingDateRequestStatusId =
                BookingDateRequestStatusEnum.DateRejected),
          );
        if (!isAccepted) {
          return (this.bookingDateRequests = bookingDateRequests);
        }
        // SET INITIAL DATE REQUEST ON BOOKED
        bookingDateRequests[
          bookingDateRequests.indexOf(bookingDateRequest)
        ].BookingDateRequestStatusId = BookingDateRequestStatusEnum.DateBooked;
        this.bookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    isBookingTicketVoucherCancelRequestAllowed(bookingTicketVoucher) {
      try {
        return (
          this.bookingTicket.BookingTicketTypeId ==
            BookingTicketTypesEnum.DifferentArrivalDays &&
          bookingTicketVoucher?.BookingTicketVoucherStatusId ==
            BookingTicketVoucherStatusEnum.DatesBooked
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async requestBookingTicketCancel() {
      try {
        const bookingDateRequests = this.bookingDateRequests.filter(
          (bookingDateRequest) =>
            bookingDateRequest.BookingTicketId == this.bookingTicket?.Id,
        );
        if (
          !bookingDateRequests ||
          !Array.isArray(bookingDateRequests) ||
          !bookingDateRequests.length
        ) {
          return this.$alert("Keinen passenden Anreisetag gefunden.");
        }
        const bookingDateRequest = bookingDateRequests.find(
          (bookingDateRequest) =>
            bookingDateRequest.BookingDateRequestStatusId ==
            BookingDateRequestStatusEnum.DateBooked,
        );
        if (!bookingDateRequest) {
          return this.$alert("Keinen gebuchten Anreisetag gefunden.");
        }
        bookingDateRequest.BookingDateRequestStatusId =
          BookingDateRequestStatusEnum.DateCancelRequested;
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedBookingTicket =
          await bookingTicketsComponent.saveBookingTicket(
            this.$apollo,
            this.bookingTicket,
            this.bookingTicketVouchers,
            [bookingDateRequest],
          );
        EventBus.$emit("changeLoadingState", false);
        if (!savedBookingTicket) {
          return this.$alert("Die Buchung konnte nicht gespeichert werden.");
        }
        if (savedBookingTicket.Message) {
          this.$alert(savedBookingTicket.Message);
        }
        if (savedBookingTicket.IsSuccess) {
          if (this.loadBookingTicket) {
            return this.loadBookingTicket();
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async requestBookingTicketVoucherCancel(bookingTicketVoucher) {
      try {
        const bookingDateRequests = this.bookingDateRequests.filter(
          (bookingDateRequest) =>
            bookingDateRequest.BookingTicketVoucherId ==
            bookingTicketVoucher.Id,
        );
        if (
          !bookingDateRequests ||
          !Array.isArray(bookingDateRequests) ||
          !bookingDateRequests.length
        ) {
          return this.$alert("Keinen passenden Anreisetag gefunden.");
        }
        const bookingDateRequest = bookingDateRequests.find(
          (bookingDateRequest) =>
            bookingDateRequest.BookingDateRequestStatusId ==
            BookingDateRequestStatusEnum.DateBooked,
        );
        if (!bookingDateRequest) {
          return this.$alert("Keinen gebuchten Anreisetag gefunden.");
        }
        bookingDateRequest.BookingDateRequestStatusId =
          BookingDateRequestStatusEnum.DateCancelRequested;
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedBookingTicket =
          await bookingTicketsComponent.saveBookingTicket(
            this.$apollo,
            this.bookingTicket,
            this.bookingTicketVouchers,
            [bookingDateRequest],
          );
        EventBus.$emit("changeLoadingState", false);
        if (!savedBookingTicket) {
          return this.$alert("Die Buchung konnte nicht gespeichert werden.");
        }
        if (savedBookingTicket.Message) {
          this.$alert(savedBookingTicket.Message);
        }
        if (savedBookingTicket.IsSuccess) {
          if (this.loadBookingTicket) {
            return this.loadBookingTicket();
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    bookingTicket() {
      try {
        this.loadAddress();
        this.loadBookingTicketVouchers();
        this.loadBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    addressString() {
      try {
        if (!this.address) {
          return undefined;
        }
        let formOfAddress = this.address.FormOfAddress;
        if (formOfAddress?.toLowerCase() == "herr") {
          formOfAddress = this.$t("customer-data.gender.m");
        } else if (formOfAddress?.toLowerCase() == "frau") {
          formOfAddress = this.$t("customer-data.gender.f");
        }
        const addressString = `${formOfAddress} ${this.address.FirstName} ${this.address.LastName}, ${this.address.Street}, ${this.address.PostalCode} ${this.address.City}`;
        return addressString;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    isSaveBookingTicketAvailable() {
      try {
        if (
          this.bookingTicket?.BookingTicketStatusId ==
          BookingTicketStatusEnum.DatesCanBeBooked
        ) {
          return true;
        } else if (
          this.bookingTicketVouchers.some(
            (bookingTicketVoucher) =>
              bookingTicketVoucher.BookingTicketVoucherStatusId ==
              BookingTicketVoucherStatusEnum.DatesCanBeBooked,
          )
        ) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    isBookingTicketCancelRequestAllowed() {
      try {
        return (
          this.bookingTicket.BookingTicketTypeId !=
            BookingTicketTypesEnum.DifferentArrivalDays &&
          this.bookingTicket.BookingTicketStatusId ==
            BookingTicketStatusEnum.DatesBooked
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
