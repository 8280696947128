<template lang="pug">
  .vouchers-booking-step
    .row
      .col-md-6.col-sm-12.mb-3(v-for="_bookingTicketVoucher in bookingTicketVouchers")
        .booking-ticket-voucher.h-100
          .card.h-100
            .card-header
              .float-left
                h5.font-weight-bold Gutschein: {{ _bookingTicketVoucher.VoucherId }}
              .float-right
                a(@click="deleteBookingTicketVoucher(_bookingTicketVoucher)")
                  font-awesome-icon.img-button(:icon="['fas', 'xmark']" size="xl")
            .card-body
              voucher(
                :voucher-id="_bookingTicketVoucher.VoucherId"
                :is-preview="false"
                :show-host="true"
              )
      .col-md-6.col-sm-12.mb-3
        .card.mb-3.h-100
          .card-header
            .float-left
              h5.font-weight-bold {{ $t('addVoucher') }}
          .card-body
            host-vouchers-select(
              v-if="availableHosts && Array.isArray(availableHosts) && availableHosts.length"
              :hosts="availableHosts"
              :voucher="voucher"
              :booking-ticket-vouchers="bookingTicketVouchers"
              :change-voucher="changeVoucher"
            )
            .form-group
              label.form-label {{ $t('voucherNumber') }}
              input.form-control(type="text" v-model="voucherId" :disabled="voucher")
            .form-group.mt-2
              label.form-label {{ $t('code') }}
              input.form-control(type="text" v-model="code" :disabled="voucher")
            button.button.button-primary.button-tbook.w-100.mt-2(@click="_addBookingTicketVoucher")
              font-awesome-icon.mr-2(:icon="['fas', 'plus']")
              span {{ $t('addVoucher') }}

    .row
      .col-12
        .card
          .card-header
            h5.font-weight-bold {{ $t('bookingTypes') }}:
          .card-body
            .row
              .col-12
                h6.font-weight-bold {{ $t("bookingTicketTypes." + BookingTicketTypesEnum.SameArrivalDay) }}
                span {{ $t("bookingTicketTypes.explanations." + BookingTicketTypesEnum.SameArrivalDay) }}
            .row.mt-2
              .col-12
                h6.font-weight-bold {{ $t("bookingTicketTypes." + BookingTicketTypesEnum.CombineTravelPeriod) }}
                span {{ $t("bookingTicketTypes.explanations." + BookingTicketTypesEnum.CombineTravelPeriod) }}
            .row.mt-2
              .col-12
                h6.font-weight-bold {{ $t("bookingTicketTypes." + BookingTicketTypesEnum.DifferentArrivalDays) }}
                span {{ $t("bookingTicketTypes.explanations." + BookingTicketTypesEnum.DifferentArrivalDays) }}

            .row.mt-3
              .col-md-4.col-sm-12
                .row
                  .col-12
                    button.button.button-primary.w-100.h-100(
                      @click="bookingTicket.BookingTicketTypeId = BookingTicketTypesEnum.SameArrivalDay"
                      :class="{ 'button-tdays': bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.SameArrivalDay, 'button-td-grey': bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.SameArrivalDay, 'disabled': !isSameArrivalDayAvailable.IsSuccess }"
                      :disabled="!isSameArrivalDayAvailable.IsSuccess"
                    )
                      br
                      font-awesome-icon(:icon="['fas', 'calendar-day']" size="4x")
                      br
                      br
                      p(style="font-size: 20px;") {{ $t("bookingTicketTypes." + BookingTicketTypesEnum.SameArrivalDay) }}

                .row.mt-3(v-if="!isSameArrivalDayAvailable.IsSuccess")
                  .col-12
                    .text-center
                      span {{ isSameArrivalDayAvailable.Message }}

              .col-md-4.col-sm-12
                .row
                  .col-12
                    button.button.button-primary.w-100.h-100(
                      @click="bookingTicket.BookingTicketTypeId = BookingTicketTypesEnum.CombineTravelPeriod"
                      :class="{ 'button-tdays': bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.CombineTravelPeriod, 'button-td-grey': bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.CombineTravelPeriod, 'disabled': !isCombineTravelPeriodAvailable.IsSuccess }"
                      :disabled="!isCombineTravelPeriodAvailable.IsSuccess"
                    )
                      br
                      font-awesome-icon(:icon="['fas', 'calendar-plus']" size="4x")
                      br
                      br
                      p(style="font-size: 20px;") {{ $t("bookingTicketTypes." + BookingTicketTypesEnum.CombineTravelPeriod) }}

                .row.mt-3(v-if="!isCombineTravelPeriodAvailable.IsSuccess")
                  .col-12
                    .text-center
                      span {{ $t(isCombineTravelPeriodAvailable.Message) }}

              .col-md-4.col-sm-12
                .row
                  .col-12
                    button.button.button-primary.w-100.h-100(
                      @click="bookingTicket.BookingTicketTypeId = BookingTicketTypesEnum.DifferentArrivalDays"
                      :class="{ 'button-tdays': bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays, 'button-td-grey': bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.DifferentArrivalDays, 'disabled': !areDifferentArrivalDaysAvailable.IsSuccess }"
                      :disabled="!areDifferentArrivalDaysAvailable.IsSuccess"
                    )
                      br
                      font-awesome-icon(:icon="['fas', 'calendar-days']" size="4x")
                      br
                      br
                      p(style="font-size: 20px;") {{ $t("bookingTicketTypes." + BookingTicketTypesEnum.DifferentArrivalDays) }}

                .row.mt-3(v-if="!areDifferentArrivalDaysAvailable.IsSuccess")
                  .col-12
                    .text-center
                      span {{ $t(areDifferentArrivalDaysAvailable.Message) }}

</template>

<script>
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import Voucher from "@/views/vouchers/Voucher.vue";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import VoucherStatusEnum from "@/utils/enums/voucherStatus/VoucherStatusEnum";
import moment from "moment";
import { UtilsComponent } from "@/components/utils/UtilsComponent";
import HostVouchersSelect from "@/views/components/select/HostVouchersSelect.vue";
import HostsComponent from "@/components/hosts/HostsComponent";

export default {
  name: "VouchersBookingStep",
  components: { HostVouchersSelect, Voucher },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVouchers: {
      type: Array,
      required: true,
    },
    hosts: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    addBookingTicketVoucher: {
      type: Function,
      required: true,
    },
    deleteBookingTicketVoucher: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      voucher: undefined,
      voucherId: "",
      code: "",
      vouchers: [],
      availableHosts: [],

      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
  async mounted() {
    try {
      this.loadHosts();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    changeVoucher(voucher) {
      try {
        this.voucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadVouchers() {
      try {
        if (!this.$session.get("email")) {
          return;
        }
        const vouchersComponent = new VouchersComponent();
        let vouchers = await vouchersComponent.getVouchers(
          this.$apollo,
          undefined,
          undefined,
          this.$session.get("email"),
          [VoucherStatusEnum.Sent],
        );
        if (!vouchers) {
          return;
        }
        vouchers = vouchers.filter((voucher) =>
          moment().isBefore(voucher.ValidTill),
        );
        this.vouchers = vouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHosts() {
      try {
        const hostsComponent = new HostsComponent();
        const hosts = await hostsComponent.getHostsByVouchers(
          this.$apollo,
          this.$session.get("email"),
          [VoucherStatusEnum.Sent],
          new Date(),
        );
        if (!hosts) {
          return;
        }
        this.availableHosts = hosts;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _addBookingTicketVoucher() {
      try {
        let voucherId = this.voucherId;
        let code = this.code;
        if (this.voucher) {
          voucherId = this.voucher?.Id;
          code = this.voucher.Guid.slice(-6);
        }
        const addedBookingTicketVoucher = await this.addBookingTicketVoucher(
          voucherId,
          code,
        );
        if (!addedBookingTicketVoucher) {
          return;
        }
        if (
          !addedBookingTicketVoucher.IsSuccess &&
          addedBookingTicketVoucher.Message
        ) {
          return this.$alert(addedBookingTicketVoucher.Message);
        }
        if (addedBookingTicketVoucher.IsSuccess) {
          this.voucher = undefined;
          this.voucherId = "";
          this.code = "";
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    isSameArrivalDayAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getIsSameArrivalDayAvailable(
          this.hosts,
          this.products,
          this.$session.get("lang"),
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    isCombineTravelPeriodAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getIsCombineTravelPeriodAvailable(
          this.bookingTicketVouchers,
          this.hosts,
          this.$session.get("lang"),
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    areDifferentArrivalDaysAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getAreDifferentArrivalDaysAvailable(
          this.bookingTicketVouchers,
          this.hosts,
          this.$session.get("lang"),
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
