import gql from "graphql-tag";

const _props = `
    Id
    Match1
    Match2
    PAdresse1
    PAdresse2
    PAdresse3
    PAdresse4
    PLAND
    PPLZ
    PORT
    RAdresse1
    RAdresse2
    RAdresse3
    RAdresse4
    RAdresse5
    RAdresse6
    RAdresse7
    RLAND
    RPLZ
    RORT
    Ansprechpartner
    TELEFON1
    TELEFON2
    TELEFAX1
    TELEFAX2
    EMAIL1
    EMAIL2
    Internetadresse1
    Internetadresse2
    PASSWD
    ProvisionFP
    Verkaufsgebuehren
    Gesperrt_Bis
    CreatedAt
    Stars
    CityRegion
    ThirdPartyId
    Description
    Name
    Longitude
    Latitude
    AccountOwner
    IBAN        
    HTMLTitel
    HTMLSubtitel
    HTMLBlock01
    HTMLBlock02
    HTMLBlock03
    HTMLBlock04        
    LastOpenedBy
    LastOpenedAt
    InsolventSince
    PropertyId
    ChannelPropertyTypeId
`;

export const Hosts = {
    Queries: {
        Host: gql`
        query host($id: ID, $voucherId: ID) {
            host(id: $id, voucherId: $voucherId) {
                ${_props}
            }
        }`,
        Hosts: gql`
        query hosts($hostPoolId: ID, $bookingTicketId: ID, $filter: String, $offset: Int, $limit: Int) {
            hosts(hostPoolId: $hostPoolId, bookingTicketId: $bookingTicketId, filter: $filter, offset: $offset, limit: $limit) {
                ${_props}
            }
        }`,
        HostsCount: gql`
        query hostsCount($bookingTicketId: ID) {
            hostsCount(bookingTicketId: $bookingTicketId)
        }`,
        HostsByVouchers: gql`
        query hostsByVouchers($email: String, $voucherStatusIds: [ID], $validTill: Date) {
            hostsByVouchers(email: $email, voucherStatusIds: $voucherStatusIds, validTill: $validTill) {
                ${_props}
            }
        }`,
    },
};
