import gql from "graphql-tag";

const _props = `
    Id
    Guid
    FirstName
    LastName
    FormOfAddress
    Title
    Company
    Street
    Street2
    PostalCode
    City
    Country
    Phone
    Email
    BirthDate
`;

export const Addresses = {
    Queries: {
        Address: gql`
        query address($id: ID) {
            address(id: $id) {
                ${_props}
            }
        }`,
        Addresses: gql`
        query addresses($filter: String, $voucherId: ID, $offset: Int, $limit: Int) {
            addresses(filter: $filter, voucherId: $voucherId, offset: $offset, limit: $limit) {
                ${_props}
            }
        }`,
    },
    Mutations: {
        SaveAddress: gql`
        mutation saveAddress($address: AddressesInput) {
            saveAddress(address: $address) {
                IsSuccess
                Message
                StringData
            }
        }`,
    }
};
