import gql from "graphql-tag";

export const FellowTravelers = {
    Queries: {
        FellowTravelers: gql`
        query fellowTravelers($bookingTicketId: ID, $bookingTicketVoucherId: ID) {
            fellowTravelers(bookingTicketId: $bookingTicketId, bookingTicketVoucherId: $bookingTicketVoucherId) {
                Id
                FirstName
                LastName
                BirthDate
                Country
                BookingTicketId
                BookingTicketVoucherId
            }
        }`,
    }
}
