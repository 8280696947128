<template lang="pug">
.hyper-guest-rate
  //.row
    .col-12
      h5 {{ $t("hyperGuest.additionalInformation") }}:

  .row.mt-2(v-if="rate.remarks && Array.isArray(rate.remarks) && rate.remarks.length")
    .col-12
      h6.font-weight-bold {{ $t("hyperGuest.info") }}
      p(v-for="remark in rate.remarks") {{ remark }}

  .row.mt-2(v-if="fees && Array.isArray(fees) && fees.length")
    .col-12
      h6.font-weight-bold {{ $t("hyperGuest.fees") }}
      p(v-for="fee in fees" v-if="fees") {{ fee.description }}

  .row.mt-2(v-if="taxes && Array.isArray(taxes) && taxes.length")
    .col-12
      h6.font-weight-bold {{ $t("hyperGuest.taxes") }}
      p(v-for="tax in taxes") {{ tax.description }}

  .row.mt-2(v-if="rate.cancellationPolicies && Array.isArray(rate.cancellationPolicies) && rate.cancellationPolicies.length")
    .col-12
      h6.font-weight-bold {{ $t("hyperGuest.cancellationPolicies") }}
      p(v-for="cancellationPolicy in rate.cancellationPolicies") {{ getCancellationPolicyText(cancellationPolicy) }}

</template>

<script>
export default {
  props: {
    rate: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getCancellationPolicyText(cancellationPolicy) {
      try {
        let cancellationPolicyText = this.$t(
          "hyperGuest.cancellationPolicy"
        ).toString();
        cancellationPolicyText = cancellationPolicyText.replace(
          "daysBefore",
          cancellationPolicy.daysBefore
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          "cancellationDeadlineHour",
          cancellationPolicy.cancellationDeadlineHour
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          "amount",
          cancellationPolicy.amount
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          "penaltyType",
          cancellationPolicy.penaltyType
        );
        cancellationPolicyText = cancellationPolicyText.replace(
          " percent",
          "%"
        );
        return cancellationPolicyText;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  computed: {
    fees() {
      try {
        if (!this.rate || !this.rate.prices) {
          return undefined;
        }
        return this.rate.prices?.fees;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    taxes() {
      try {
        if (!this.rate || !this.rate.prices) {
          return undefined;
        }
        let prices = this.rate.prices[this.rate?.payment?.charge];
        if (!prices) {
          prices = this.rate?.prices["sell"];
        }
        return prices?.taxes;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
