<template lang="pug">
.booking-date-request
  .row
    .col-12
      .float-left.mr-3
        template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.SameArrivalDay")
          span {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }}
          span(v-if="isBookingDateRequestStatusVisible")
            | &nbsp;({{ $t("bookingDateRequestStatus." + localBookingDateRequest.BookingDateRequestStatusId ) }})

          ul.mt-3(v-if="nights && Array.isArray(nights) && nights.length")
            li(v-for="night in nights")
              h6 {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(night, 'days').toDate() | formatDateWithDay($t("lang")) }}

        template(v-else-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.CombineTravelPeriod")
          span {{ bookingDateRequest.FromDate | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.ToDate) | formatDateWithDay($t("lang")) }}

        template(v-else-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
          span {{ bookingDateRequest.FromDate | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.ToDate) | formatDateWithDay($t("lang")) }}

        span(v-if="bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.SameArrivalDay && isBookingDateRequestStatusVisible")
          | &nbsp;({{ $t("bookingDateRequestStatus." + localBookingDateRequest.BookingDateRequestStatusId ) }})

      .float-left(v-if="isEdit && localBookingDateRequest.BookingDateRequestStatusId == BookingDateRequestStatusEnum.DateCanBeBooked")
        input.mr-1(type="radio", :id="'accept-' + bookingDateRequest.Id" :value="BookingDateRequestStatusEnum.DateBooked" v-model="bookingDateRequest.BookingDateRequestStatusId" @change="checkBookingDateRequest(bookingDateRequest, true, bookingTicketVoucher)")
        label(:for="'accept-' + bookingDateRequest.Id") annehmen

        input.ml-2.mr-1(type="radio", :id="'reject-' + bookingDateRequest.Id" :value="BookingDateRequestStatusEnum.DateRejected" v-model="bookingDateRequest.BookingDateRequestStatusId" @change="checkBookingDateRequest(bookingDateRequest, false, bookingTicketVoucher)")
        label(:for="'reject-' + bookingDateRequest.Id") ablehnen

</template>

<script>
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import ProductsComponent from "@/components/products/ProductsComponent";
import moment from "moment";

export default {
  name: "BookingDateRequest",
  props: {
    bookingDateRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingTicketVouchers: {
      type: Array,
      required: false,
    },
    checkBookingDateRequest: {
      type: Function,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localBookingDateRequest: JSON.parse(
        JSON.stringify(this.bookingDateRequest),
      ),
      moment: moment,
      products: [],
      nights: [],
      totalNights: 0,
      voucher: undefined,
      product: undefined,
      BookingTicketTypesEnum: BookingTicketTypesEnum,
      BookingDateRequestStatusEnum: BookingDateRequestStatusEnum,
    };
  },
  async mounted() {
    try {
      this.handleBookingTicketVouchers();
      this.loadVoucher();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async handleBookingTicketVouchers() {
      try {
        if (
          !this.bookingTicketVouchers ||
          !Array.isArray(this.bookingTicketVouchers) ||
          !this.bookingTicketVouchers.length
        ) {
          return;
        }
        const products = [];
        const nights = [];
        let totalNights = 0;
        const vouchersComponent = new VouchersComponent();
        const productsComponent = new ProductsComponent();
        for (const bookingTicketVoucher of this.bookingTicketVouchers) {
          const voucher = await vouchersComponent.getVoucher(
            this.$apollo,
            bookingTicketVoucher.VoucherId,
          );
          if (!voucher) {
            continue;
          }
          const product = await productsComponent.getProduct(
            this.$apollo,
            voucher.ProductId,
          );
          if (!product) {
            continue;
          }
          products.push(product);
          nights.push(product.Nights);
          totalNights += product.Nights;
        }
        this.products = products;
        this.nights = nights;
        this.totalNights = totalNights;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadVoucher() {
      try {
        if (!this.bookingTicketVoucher) {
          return;
        }
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.bookingTicketVoucher?.VoucherId,
        );
        if (!voucher) {
          return;
        }
        this.voucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        if (!this.voucher) {
          return;
        }
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.voucher?.ProductId,
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    bookingDateRequest() {
      try {
        this.localBookingDateRequest = JSON.parse(
          JSON.stringify(this.bookingDateRequest),
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingTicketVouchers() {
      try {
        this.handleBookingTicketVouchers();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingTicketVoucher() {
      try {
        if (!this.bookingTicketVoucher) {
          return;
        }
        this.loadVoucher();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    voucher() {
      try {
        if (!this.voucher) {
          return;
        }
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    combinedProducts() {
      try {
        if (
          !this.products ||
          !Array.isArray(this.products) ||
          !this.products.length
        ) {
          return undefined;
        }
        const combinedProducts = [];
        for (const product of this.products) {
          let combinedProduct = combinedProducts.find(
            (combinedProduct) => combinedProduct.Id == product.Id,
          );
          if (!combinedProduct) {
            combinedProducts.push(product);
          } else {
            combinedProduct.Personen += product.Personen;
          }
        }
        return combinedProducts;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    isBookingDateRequestStatusVisible() {
      try {
        return (
          this.localBookingDateRequest?.BookingDateRequestStatusId !=
            BookingDateRequestStatusEnum.DateCanBeBooked &&
          this.localBookingDateRequest?.BookingDateRequestStatusId !=
            BookingDateRequestStatusEnum.DateCreated
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
