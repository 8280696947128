<template lang="pug">
#app.app
  td-loading-overlay(v-if="isLoading")
  .text-center(style="padding-top: 400px;" v-if="touriBookMaintenance && touriBookMaintenance.IsActive")
    h1 Wir befinden uns derzeit im Wartungsmodus und sind in Kürze wieder für Sie da. 👷‍♀️
  template(v-else)
    template(v-if="$route.meta.isLogin")
      login

    template(v-else)
      header#globalhead
        .container-fluid
          .row
            .col-12
              .d-flex.align-items-center
                a(href="/")
                  img.main-logo(:src="`${publicPath}images/touribook-white-plain-basic-250.png`" alt="")

                .ml-auto.d-flex
                  button.button.button-primary.button-tpics.mr-4(@click="openReportErrorModal")
                    font-awesome-icon.mr-2(:icon="['fas', 'bug']")
                    span {{ $t("report-error-modal.headline") }}
                  .py-2
                    img(:src="`${publicPath}images/flag_ger.jpg`" style="height: 20px; cursor: pointer;" @click="changeLanguage('de')" ).pr-2
                    img(:src="`${publicPath}images/flag_gb.jpg`" style="height: 20px; cursor: pointer;" @click="changeLanguage('en')").px-2
                    .button-help-modal.txt-white(@click="showHelpModal")
                      i
                        font-awesome-icon(:icon="['fas', 'info-circle']")
                    a.button.button-link.txt-white(@click="logout")
                      font-awesome-icon.mr-2(:icon="['fas', 'right-from-bracket']")
                      span {{ $t("buttons.logout") }}

      main.flex-shrink-0(role="main")
        .container-fluid
          .row
            .col-12
              router-view(:key="$route.fullPath")

</template>

<script>
import EventBus from "./event-bus";
import { Maintenances } from "@/graphql/maintenances/Maintenances.ts";
import TdLoadingOverlay from "@/views/partials/loading/td-loading-overlay.vue";
import HelpModal from "@/views/modals/help-modal.vue";
import Login from "@/views/login/Login.vue";
import ReportError from "@/views/modals/report-error.vue";

export default {
  name: "App",
  components: { Login, TdLoadingOverlay },
  data() {
    return {
      isLoading: false,
      publicPath: process.env.BASE_URL,

      touriBookMaintenance: undefined,
    };
  },
  mounted() {
    try {
      EventBus.$on(
        "changeLoadingState",
        function (isLoading) {
          this.isLoading = isLoading;
        }.bind(this),
      );
      this.$i18n.locale = "de";
      localStorage.setItem("localeSettings", "de");
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    openReportErrorModal() {
      try {
        this.$modal.show(
          ReportError,
          {
            voucherId: this.$session.get("voucherId"),
          },
          {
            scrollable: true,
            resizable: true,
            height: "auto",
            width: "80%",
          },
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    logout() {
      try {
        this.$session.destroy();
        EventBus.$emit("changeLoginState", false);
        this.$router.push("/login");
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    changeLanguage(lang) {
      try {
        this.$i18n.locale = lang;
        localStorage.setItem("localeSettings", lang);
        EventBus.$emit("changeLanguage", lang);
      } catch (e) {
        localStorage.setItem("localeSettings", "de");
      }
    },
    showHelpModal() {
      try {
        this.$modal.show(
          HelpModal,
          {
            viewDescription: this.$route.name,
          },
          {
            scrollable: true,
            resizable: true,
            height: "auto",
            width: "80%",
          },
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  apollo: {
    touriBookMaintenance: {
      query: Maintenances.Queries.TouriBookMaintenance,
      fetchPolicy: "no-cache",
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/styles/base";
</style>
