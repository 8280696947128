<template lang="pug">
.fellow-travelers-booking-step
  .row
    .col-12
      h4 {{ $t('fellowTravelers') }}:
  template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
    .row(v-for="bookingTicketVoucher in bookingTicketVouchers")
      .col-12
        .card
          .card-header
            .row
              .col-12
                h5 {{ bookingTicketVoucher.VoucherId }}
          .card-body
            fellow-travelers-editing(
              :fellow-travelers="fellowTravelers"
              :booking-ticket="bookingTicket"
              :booking-ticket-voucher="bookingTicketVoucher"
              :address-countries="addressCountries"
            )
  fellow-travelers-editing(
    v-else
    :fellow-travelers="fellowTravelers"
    :booking-ticket="bookingTicket"
    :address-countries="addressCountries"
  )

</template>

<script>
import FellowTravelersEditing from "@/views/fellowTravelers/FellowTravelersEditing.vue";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export default {
  name: "FellowTravelersBookingStep",
  components: { FellowTravelersEditing },
  props: {
    fellowTravelers: {
      type: Array,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVouchers: {
      type: Array,
      required: true,
    },
    addressCountries: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
};
</script>

<style scoped></style>
