<template lang="pug">
.d-flex.align-items-center
  .booking-date-request-editing(@click="_selectBookingDateRequest" :class="{ 'selectable': !isDisabled && selectBookingDateRequest, 'selected': selectedBookingDateRequest == bookingDateRequest }")
    template(v-if="!bookingDateRequest.Date")
      h5.p-0(v-if="selectedBookingDateRequest != bookingDateRequest") Bitte klicken Sie hier...
      h5.p-0(v-else) Wählen Sie jetzt ein Datum aus...
    template(v-else)
      template(v-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.SameArrivalDay")
        h5 {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }}
        ul.mt-3(v-if="nights && Array.isArray(nights) && nights.length")
          li(v-for="night in nights")
            h6 {{ bookingDateRequest.Date | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.Date).add(night, 'days').toDate() | formatDateWithDay($t("lang")) }}

      template(v-else-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.CombineTravelPeriod")
        h5 {{ bookingDateRequest.FromDate | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.ToDate) | formatDateWithDay($t("lang")) }}

      template(v-else-if="bookingTicket.BookingTicketTypeId == BookingTicketTypesEnum.DifferentArrivalDays")
        h5 {{ bookingDateRequest.FromDate | formatDateWithDay($t("lang")) }} - {{ moment(bookingDateRequest.ToDate) | formatDateWithDay($t("lang")) }}

  .ml-auto(v-if="!isDisabled && deleteBookingDateRequest")
    font-awesome-icon.delete-booking-date-request-editing(:icon="['fas', 'circle-xmark']" size="2x" @click="deleteBookingDateRequest(bookingDateRequest)")

</template>

<script>
import moment from "moment";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import ProductsComponent from "@/components/products/ProductsComponent";
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";

export default {
  name: "BookingDateRequestEditing",
  props: {
    bookingDateRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingTicketVouchers: {
      type: Array,
      required: false,
    },
    selectBookingDateRequest: {
      type: Function,
      required: false,
    },
    selectedBookingDateRequest: {
      type: Object,
      required: false,
    },
    deleteBookingDateRequest: {
      type: Function,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    nights: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      moment: moment,
      voucher: undefined,
      product: undefined,
      BookingTicketTypesEnum: BookingTicketTypesEnum,
      BookingTicketStatusEnum: BookingTicketStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadVoucher();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVoucher() {
      try {
        if (!this.bookingTicketVoucher) {
          return;
        }
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.bookingTicketVoucher?.VoucherId,
        );
        if (!voucher) {
          return;
        }
        this.voucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        if (!this.voucher) {
          return;
        }
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.voucher?.ProductId,
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    _selectBookingDateRequest() {
      try {
        if (!this.selectBookingDateRequest || this.isDisabled) {
          return;
        }
        this.selectBookingDateRequest(this.bookingDateRequest);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    bookingTicketVoucher() {
      try {
        if (!this.bookingTicketVoucher) {
          return;
        }
        this.loadVoucher();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    voucher() {
      try {
        if (!this.voucher) {
          return;
        }
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
