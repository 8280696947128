<template lang="pug">
.booking-date-requests-editing
  h4 {{ $t('arrivalDays') }}
  .row.mt-3(v-for="bookingDateRequest in localBookingDateRequests")
    .col-12
      booking-date-request-editing(
        :booking-date-request="bookingDateRequest"
        :booking-ticket="bookingTicket"
        :booking-ticket-voucher="bookingTicketVoucher"
        :booking-ticket-vouchers="bookingTicketVouchers"
        :nights="nights"
        :select-booking-date-request="selectBookingDateRequest"
        :selected-booking-date-request="selectedBookingDateRequest"
        :delete-booking-date-request="deleteBookingDateRequest"
        :is-disabled="isDisabled"
      )

  .row.mt-2(v-if="!areMultipleArrivalDaysAvailable && localBookingDateRequests.length == 0")
    .col-12
      button.button.button-primary.button-tbook.w-100(@click="addBookingDateRequest(bookingTicketVoucher ? bookingTicketVoucher.Id : undefined)" :class="{ 'disabled': isDisabled || localBookingDateRequests.length >= 3 }" :disabled="isDisabled || localBookingDateRequests.length >= 3")
        font-awesome-icon.mr-2(:icon="['fas', 'plus']")
        span {{ $t('addArrivalDay') }}
  .row.mt-2(v-else-if="areMultipleArrivalDaysAvailable && localBookingDateRequests.length < 3")
    .col-12
      button.button.button-primary.button-tbook.w-100(@click="addBookingDateRequest(bookingTicketVoucher ? bookingTicketVoucher.Id : undefined)" :class="{ 'disabled': isDisabled || localBookingDateRequests.length >= 3 }" :disabled="isDisabled || localBookingDateRequests.length >= 3")
        font-awesome-icon.mr-2(:icon="['fas', 'plus']")
        span {{ $t('addArrivalDay') }}
  .row.mt-2(v-if="areMultipleArrivalDaysAvailable")
    .col-12
      div(v-html="$t('maxArrivalDays')")

</template>

<script>
import BookingDateRequestEditing from "@/views/bookingDateRequests/BookingDateRequestEditing.vue";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import { UtilsComponent } from "@/components/utils/UtilsComponent";

export default {
  name: "BookingDateRequestsEditing",
  components: { BookingDateRequestEditing },
  props: {
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingTicketVouchers: {
      type: Array,
      required: false,
    },
    nights: {
      type: Array,
      required: false,
    },
    hosts: {
      type: Array,
      required: false,
    },
    selectedBookingDateRequest: {
      type: Object,
      required: false,
    },
    selectBookingDateRequest: {
      type: Function,
      required: true,
    },
    addBookingDateRequest: {
      type: Function,
      required: true,
    },
    deleteBookingDateRequest: {
      type: Function,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localBookingDateRequests: this.bookingDateRequests,
    };
  },
  async mounted() {
    try {
      this.handleBookingDateRequests();
      this.checkSelectedBookingDateRequest();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    handleBookingDateRequests() {
      try {
        let bookingDateRequests = [];
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          if (this.bookingTicketVoucher) {
            bookingDateRequests = this.bookingDateRequests.filter(
              (bookingDateRequest) =>
                bookingDateRequest.BookingTicketVoucherId ==
                this.bookingTicketVoucher?.Id,
            );
          }
        } else {
          bookingDateRequests = this.bookingDateRequests.filter(
            (bookingDateRequest) =>
              bookingDateRequest.BookingTicketId == this.bookingTicket?.Id,
          );
        }
        this.localBookingDateRequests = bookingDateRequests;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    checkSelectedBookingDateRequest() {
      try {
        if (this.selectedBookingDateRequest) {
          return;
        }
        if (!this.localBookingDateRequests?.length) {
          return;
        }
        this.selectBookingDateRequest(this.localBookingDateRequests[0]);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    "bookingTicket.BookingTicketTypeId"() {
      try {
        this.handleBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingDateRequests() {
      try {
        this.handleBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingTicketVoucher() {
      try {
        this.handleBookingDateRequests();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    areMultipleArrivalDaysAvailable() {
      try {
        const utilsComponent = new UtilsComponent();
        return utilsComponent.getAreMultipleArrivalDaysAvailable(this.hosts);
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
