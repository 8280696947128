<template lang="pug">
  optgroup(:label="host.Name")
    option(
      v-for="voucher in localVouchers"
      :value="voucher"
    ) {{ voucher.Id }}: {{ voucher.Arrangement }}

</template>

<script>
import VoucherStatusEnum from "@/utils/enums/voucherStatus/VoucherStatusEnum";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import moment from "moment/moment";

export default {
  name: "HostVouchersOptionGroup",
  props: {
    host: {
      type: Object,
      required: true,
    },
    bookingTicketVouchers: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    changeVoucher: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      vouchers: [],
      VoucherStatusEnum: VoucherStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadVouchers();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVouchers() {
      try {
        if (!this.$session.get("email")) {
          return;
        }
        const vouchersComponent = new VouchersComponent();
        let vouchers = await vouchersComponent.getVouchers(
          this.$apollo,
          this.host.Id,
          undefined,
          this.$session.get("email"),
          [VoucherStatusEnum.Sent],
          new Date(),
        );
        if (!vouchers) {
          return;
        }
        vouchers = vouchers.filter((voucher) =>
          moment().isBefore(voucher.ValidTill),
        );
        this.vouchers = vouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    "host.Id"() {
      try {
        this.loadVouchers();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    localVouchers() {
      try {
        const vouchers = this.vouchers.filter(
          (voucher) =>
            !this.bookingTicketVouchers.some(
              (bookingTicketVoucher) =>
                bookingTicketVoucher.VoucherId == voucher.Id,
            ),
        );
        return vouchers;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
