<template lang="pug">
.address
  .row(v-if="addresses && Array.isArray(addresses) && addresses.length")
    .col-12
      select.form-control(v-model="localAddress" :disabled="isDisabled")
        option(:value="undefined") Bitte wählen Sie eine Adresse aus...
        option(v-for="address in addresses" :value="address")
          span {{ address.FormOfAddress ? `${address.FormOfAddress} ` : '' }}{{ address.FirstName }} {{ address.LastName }}, {{ address.Street }}, {{ address.PostalCode }} {{ address.City }}{{ address.Country ? ` ${address.Country}` : '' }}{{ address.Phone ? `, ${address.Phone}` : '' }}{{ address.BirthDate ? `, ${moment(address.BirthDate).format('DD.MM.YYYY')}` : '' }}
  .row(v-else)
    .col-12
      .form-group
        label.form-label {{ $t("customer-data.guestMail") }}
        input.form-control(:placeholder="this.$t('customer-data.guestMailPlaceholder')" v-model="address.Email" :disabled="isDisabled")

  .row.mt-2
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.formOfAddress") }}
        select.form-control(v-model="address.FormOfAddress" :disabled="isDisabled")
          option(value="Herr") {{ $t("customer-data.gender.m") }}
          option(value="Frau") {{ $t("customer-data.gender.f") }}
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.firstName") }}
        input.form-control(:placeholder="this.$t('customer-data.firstNamePlaceholder')" v-model="address.FirstName" :disabled="isDisabled")
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.lastName") }}
        input.form-control(:placeholder="this.$t('customer-data.lastNamePlaceholder')" v-model="address.LastName" :disabled="isDisabled")

  .row
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.streetAndNumber") }}
        input.form-control(:placeholder="this.$t('customer-data.streetAndNumberPlaceholder')" v-model="address.Street" :disabled="isDisabled")
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.zipCode") }}
        input.form-control(:placeholder="this.$t('customer-data.zipCodePlaceholder')" v-model="address.PostalCode" :disabled="isDisabled")
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.city") }}
        input.form-control(:placeholder="this.$t('customer-data.cityPlaceholder')" v-model="address.City" :disabled="isDisabled")

  .row
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.country") }}
        select.form-control(v-model="address.Country" :disabled="isDisabled")
          option(:value="addressCountry.ISO31661alpha2" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.dateOfBirth") }}
        input.form-control(type="date" :placeholder="this.$t('customer-data.datePlaceholder')" :value="address.BirthDate | formatInputDate" @change="address.BirthDate = $event.target.value;" :disabled="isDisabled")
    .col-12.col-md-4
      .form-group
        label.form-label {{ $t("customer-data.phone") }}
        input.form-control(:placeholder="this.$t('customer-data.phonePlaceholder')" v-model="address.Phone" :disabled="isDisabled")

</template>

<script>
import AddressesComponent from "@/components/addresses/AddressesComponents";
import moment from "moment";

export default {
  name: "AddressDetails",
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
    addressCountries: {
      type: Array,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },

    // FUNCTIONS
    changeAddress: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      email: this.$session.get("email"),
      addresses: [],
      localAddress: undefined,
      moment: moment,
    };
  },
  async mounted() {
    try {
      this.loadAddresses();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadAddresses() {
      try {
        if (!this.email) {
          return;
        }
        const addressesComponent = new AddressesComponent();
        const addresses = await addressesComponent.getAddresses(
          this.$apollo,
          this.email,
        );
        if (!addresses) {
          return;
        }
        this.addresses = addresses;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    localAddress() {
      try {
        if (!this.localAddress) {
          return;
        }
        this.changeAddress(this.localAddress);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
