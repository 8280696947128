import gql from "graphql-tag";

export const HostImages = {
    Queries: {
        HostImages: gql`
        query hostImages($hostId: ID, $imageTypeIds: [ID]) {
            hostImages(hostId: $hostId, imageTypeIds: $imageTypeIds) {
                Id
                Guid
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                Position
                RemoteBaseUrl
                HtmlImageBlur
                HostId
                ImageId
                Image {
                    ImageName
                    FileName
                    ImageTypeId
                }
            }
        }`,
        HostImagesByProductId: gql`
        query hostImagesByProductId($productId: ID) {
            hostImagesByProductId(productId: $productId) {
                Id
                Guid
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                Position
                RemoteBaseUrl
                HtmlImageBlur
                HostId
                ImageId
                Image {
                    ImageName
                    FileName
                    ImageTypeId
                }
            }
        }`,
    },
    Mutations: {
        SaveHostImages: gql`
        mutation saveHostImages($hostId: ID, $imageIds: [ID], $userId: ID) {
            saveHostImages(hostId: $hostId, imageIds: $imageIds, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        SaveHostImagesSort: gql`
        mutation saveHostImagesSort($hostImageIds: [ID], $userId: ID) {
            saveHostImagesSort(hostImageIds: $hostImageIds, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
    }
};
