const { HostImages } = require("@/graphql/hostImages/HostImages.ts");

class HostImagesComponent {
  async getHostImages(apollo, hostId, imageTypeIds) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!hostId) {
        return undefined;
      }
      const hostImages = await apollo
        .query({
          query: HostImages.Queries.HostImages,
          fetchPolicy: "no-cache",
          variables: {
            hostId: hostId,
            imageTypeIds: imageTypeIds,
          },
        })
        .then(({ data }) => data?.hostImages)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!hostImages) {
        return undefined;
      }
      return hostImages;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = HostImagesComponent;
