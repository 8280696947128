<template lang="pug">
.fellow-travelers-editing
  fellow-traveler-editing(
    v-for="fellowTraveler in localFellowTravelers"
    :fellow-traveler="fellowTraveler"
    :booking-ticket="bookingTicket"
    :booking-ticket-voucher="bookingTicketVoucher"
    :address-countries="addressCountries"
    :key="fellowTravelers.indexOf(fellowTraveler)"
  )

</template>

<script>
import FellowTravelerEditing from "@/views/fellowTravelers/FellowTravelerEditing.vue";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export default {
  name: "FellowTravelersEditing",
  components: { FellowTravelerEditing },
  props: {
    fellowTravelers: {
      type: Array,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    addressCountries: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      localFellowTravelers: [],
    };
  },
  mounted() {
    try {
      this.handleFellowTravelers();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  watch: {
    fellowTravelers() {
      try {
        this.handleFellowTravelers();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  methods: {
    handleFellowTravelers() {
      try {
        if (
          this.bookingTicket?.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          this.localFellowTravelers = this.fellowTravelers.filter(
            (ft) => ft.BookingTicketVoucherId == this.bookingTicketVoucher?.Id
          );
        } else {
          this.localFellowTravelers = this.fellowTravelers.filter(
            (ft) => ft.BookingTicketId == this.bookingTicket?.Id
          );
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
