<template lang="pug">
.fellow-traveler(v-if="fellowTraveler.FirstName && fellowTraveler.LastName && fellowTraveler.BirthDate && fellowTraveler.Country")
  .row
    .col-12
      span {{ fellowTraveler.FirstName }} {{ fellowTraveler.LastName }}, {{ fellowTraveler.BirthDate | formatDate($t('lang')) }}, {{ fellowTraveler.Country }}

</template>

<script>
export default {
  name: "FellowTraveler",
  props: {
    fellowTraveler: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
