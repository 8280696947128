import gql from "graphql-tag";

const _props = `
    Id
    Guid
    Date
    BookingDateRequestStatusId
    BookingTicketId
    BookingTicketVoucherId
    CreatedAt
    UpdatedAt
    RefusalReason
    Inactive
    BookedByQuota
    ProductQuotaID
    CreatedBy
    UpdatedBy
    FromDate
    ToDate
`;

export const BookingDateRequests = {
    Queries: {
        BookingDateRequests: gql`
        query bookingDateRequests($bookingTicketId: ID, $bookingTicketVoucherId: ID) {
            bookingDateRequests(bookingTicketId: $bookingTicketId, bookingTicketVoucherId: $bookingTicketVoucherId) {
                ${_props}
            }
        }`,
    },
    Mutations: {
        DeleteBookingDateRequest: gql`
        mutation deleteBookingDateRequest($id: ID) {
            deleteBookingDateRequest(id: $id) {
                IsSuccess
                Message
            }
        }`,
    },
};
