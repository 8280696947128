import gql from "graphql-tag";

const _props = `
    Id
    Guid
    BookingTicketStatusId
    CreatedAt
    UpdatedAt
    TimeLimitForProcessingTicketDays
    TimeLimitForBookingDateDays
    IsBindingBooking
    InternalComment
    MessageForCustomer
    TicketWatchedFirstTimeByHost
    StatusRequestByCustomer
    EmailRequestSentToHost
    EmailRequestReminderMailSentToHost
    EmailRequestSecondReminderMailSentToHost
    StatusRequestAutomaticallyDeniedBySystem
    EmailRequestAutomaticallyDeniedSentToHost
    EmailRequestAutomaticallyDeniedSentToCustomer
    StatusRequestDeniedByHost
    EmailRequestDeniedSentToCustomer
    StatusRequestAcceptedByHost
    EmailRequestAcceptedSentToCustomer
    EmailRequestAcceptedReminderSentToCustomer
    EmailRequestAcceptedSecondReminderSentToCustomer
    StatusBookedByCustomer
    EmailBookedSentToHost
    StatusAutomaticallyCancelledBySystem
    EmailAutomaticallyCancelledSentToCustomer
    EmailAutomaticallyCancelledSentToHost
    StatusHostMissedAllDates
    EmailHostMissedAllDatesSentToHost
    StatusQuotaCanBeSelected
    EmailQuotaCanBeSelectedSentToGuest
    Inactive
    EmailSentCustomerAltDatesAvailableByHost
    EmailSentCustomerAltDatesAvailableByHostReminder
    IsBookingCancelRequested
    FastAcceptedDatePerMail
    DatesRequestedAt
    DatesCanBeBookedAt
    DatesBookedAt
    DatesRejectedAt
    DatesCancelRequestedAt
    DatesCancelledAt
    HostDatesAcceptedCancelAt
    HostDatesDeclinedCancelAt
    CreatedBy
    UpdatedBy
    ThirdPartyBookingId
    AddressId
    BookingTicketTypeId
    OriginPlatformId
`;

export const BookingTickets = {
    Queries: {
        BookingTicket: gql`
        query bookingTicket($id: ID, $guid: String) {
            bookingTicket(id: $id, guid: $guid) {
                ${_props}
            }
        }`,
        BookingTickets: gql`
        query bookingTickets(
            $hostIds: [ID]
            $bookingTicketStatusIds: [Int]
            $offset: Int
            $limit: Int
            $filter: String
        ) {
            bookingTickets(
                hostIds: $hostIds
                filter: $filter
                bookingTicketStatusIds: $bookingTicketStatusIds
                offset: $offset
                limit: $limit
            ) {
                ${_props}
            }
        }`,
    },
    Mutations: {
        SaveBookingTicket: gql`
        mutation saveBookingTicket($bookingTicket: BookingTicketsInput, $bookingTicketVouchers: [BookingTicketVouchersInput], $bookingDateRequests: [BookingDateRequestsInput], $address: AddressesInput, $fellowTravelers: [FellowTravelersInput]) {
            saveBookingTicket(bookingTicket: $bookingTicket, bookingTicketVouchers: $bookingTicketVouchers, bookingDateRequests: $bookingDateRequests, address: $address, fellowTravelers: $fellowTravelers) {
                IsSuccess
                Message
                Guid
            }
        }`,
        CopyBookingTicket: gql`
        mutation copyBookingTicket($bookingTicketId: ID) {
            copyBookingTicket(bookingTicketId: $bookingTicketId) {
                IsSuccess
                Message
            }
        }`,
        DeleteBookingTicket: gql`
        mutation deleteBookingTicket($bookingTicketId: ID) {
            deleteBookingTicket(bookingTicketId: $bookingTicketId) {
                IsSuccess
                Message
            }
        }`,
        RequestCancelBookingTicket: gql`
        mutation requestCancelBookingTicket($bookingTicketId: ID) {
            requestCancelBookingTicket(bookingTicketId: $bookingTicketId) {
                IsSuccess
                Message
            }
        }`,
    },
};
