<template lang="pug">
.booking-tickets
  .row
    .col-12
      .d-flex.align-items-center
        .mr-auto
          h4 {{ $t("booking-requests") }}
        .ml-auto(v-if="bookingTickets && Array.isArray(bookingTickets) && bookingTickets.length")
          button.button.button-primary.button-tbook(@click="isEdit = !isEdit")
            template(v-if="isEdit")
              font-awesome-icon.mr-2(:icon="['fas', 'list']")
              span {{ $t("toOverview") }}
            template(v-else)
              font-awesome-icon.mr-2(:icon="['fas', 'pen']")
              span {{ $t("toEdit") }}

  .row.mt-1
    .col-md-4.col-sm-12.mb-3
      router-link.booking-ticket-link.add-booking-card(:to="{ name: 'BookingTicketNew' }")
        .card.booking-ticket-card.h-100
          .card-body.d-flex.align-items-center.text-center
            .ml-auto.mr-auto
              .row
                .col-12
                  font-awesome-icon.mt-2(:icon="['fas', 'circle-plus']" size="10x")
              .row
                .col-12
                  h3.mt-3 {{ $t('createNewBooking') }}

    .col-md-4.col-sm-12.mb-3(v-for="bookingTicket in bookingTickets")
      router-link.booking-ticket-link(
        :to="{ name: 'BookingTicketDetails', params: { bookingTicketGuid: bookingTicket.Guid }, query: bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.Created ? { bookingStep: 1 } : {} }"
      )
        booking-ticket-card(
          :booking-ticket="bookingTicket"
          :email="email"
        )
      .row(v-if="isEdit")
        .col-12
          button.button.button-primary.button-tbook.w-50(@click="copyBookingTicket(bookingTicket.Id)")
            font-awesome-icon.mr-2(:icon="['fas', 'copy']")
            span {{ $t("copy") }}
          button.button.button-primary.button-tpics.w-50(
            @click="deleteBookingTicket(bookingTicket.Id)"
            :disabled="bookingTicket.BookingTicketStatusId != BookingTicketStatusEnum.Created"
            :class="{ 'disabled': bookingTicket.BookingTicketStatusId != BookingTicketStatusEnum.Created }"
          )
            font-awesome-icon.mr-2(:icon="['fas', 'xmark']")
            span {{ $t("delete") }}


</template>

<script>
import { BookingTicketsComponent } from "@/components/bookingTickets/BookingTicketsComponent";
import BookingTicketOverview from "@/views/bookingTickets/BookingTicketOverview.vue";
import BookingTicketCard from "@/views/bookingTickets/BookingTicketCard.vue";
import { BookingTicketStatusEnum } from "@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import EventBus from "@/event-bus";

export default {
  name: "BookingTickets",
  components: { BookingTicketCard, BookingTicketOverview },
  data() {
    return {
      email: this.$session.get("email"),
      bookingTickets: [],
      offset: 0,
      limit: 15,

      isEdit: false,

      BookingTicketStatusEnum: BookingTicketStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadBookingTickets();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadBookingTickets() {
      try {
        if (!this.email) {
          return;
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const bookingTickets = await bookingTicketsComponent.getBookingTickets(
          this.$apollo,
          undefined,
          undefined,
          this.email,
          this.offset,
          this.limit,
        );
        EventBus.$emit("changeLoadingState", false);
        if (!bookingTickets) {
          return this.$alert("Es konnten keine Buchungen geladen werden.");
        }
        this.bookingTickets = bookingTickets;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadMoreBookingTickets() {
      try {
        this.offset += this.limit;
        this.loadBookingTickets();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async copyBookingTicket(bookingTicketId) {
      try {
        const confirmed = await this.$confirm(
          this.$t("copyBookingTicketConfirmation"),
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const copiedBookingTicket =
          await bookingTicketsComponent.copyBookingTicket(
            this.$apollo,
            bookingTicketId,
          );
        EventBus.$emit("changeLoadingState", false);
        if (!copiedBookingTicket.IsSuccess) {
          if (!copiedBookingTicket.Message) {
            return;
          }
          return this.$alert(copiedBookingTicket.Message);
        }
        this.loadBookingTickets();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async deleteBookingTicket(bookingTicketId) {
      try {
        const confirmed = await this.$confirm(
          this.$t("deleteBookingTicketConfirmation"),
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const deletedBookingTicket =
          await bookingTicketsComponent.deleteBookingTicket(
            this.$apollo,
            bookingTicketId,
          );
        EventBus.$emit("changeLoadingState", false);
        if (!deletedBookingTicket.IsSuccess) {
          if (!deletedBookingTicket.Message) {
            return;
          }
          return this.$alert(deletedBookingTicket.Message);
        }
        this.loadBookingTickets();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
