<template lang="pug">
.booking-ticket-voucher-editing(:class="{ 'disabled': isDisabled }")
  .row
    .col-md-6.col-sm-12
      booking-date-picker(
        :select-date="selectDate"
        :is-disabled="isDisabled || !selectedBookingDateRequest"
      )
    .col-md-6.col-sm-12.mt-md-0.mt-sm-3
      booking-date-requests-editing(
        :booking-date-requests="bookingDateRequests"
        :selected-booking-date-request="selectedBookingDateRequest"
        :select-booking-date-request="selectBookingDateRequest"
        :add-booking-date-request="addBookingDateRequest"
        :is-disabled="isDisabled"
      )

</template>

<script>
import Voucher from "@/views/vouchers/Voucher.vue";
import BookingDatePicker from "@/views/components/datepicker/BookingDatePicker.vue";
import VouchersComponent from "@/components/vouchers/VouchersComponent";
import ProductsComponent from "@/components/products/ProductsComponent";
import moment from "moment";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";
import BookingDateRequestsEditing from "@/views/bookingDateRequests/BookingDateRequestsEditing.vue";

export default {
  name: "BookingTicketVoucherEditing",
  components: { BookingDateRequestsEditing, BookingDatePicker, Voucher },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingDateRequests: {
      type: Array,
      required: false,
    },
    selectedBookingDateRequest: {
      type: Object,
      required: false,
    },
    selectBookingDateRequest: {
      type: Function,
      required: true,
    },
    addBookingDateRequest: {
      type: Function,
      required: true,
    },
    selectDate: {
      type: Function,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      voucher: undefined,
      product: undefined,
      nights: 0,
      moment: moment,
      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
  async mounted() {
    try {
      this.loadVoucher();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVoucher() {
      try {
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.bookingTicketVoucher?.VoucherId
        );
        if (!voucher) {
          return;
        }
        this.voucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.voucher?.ProductId
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    "bookingTicketVoucher.VoucherId"() {
      try {
        this.loadVoucher();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    product() {
      try {
        if (!this.product) {
          return;
        }
        this.nights = this.product.Nights;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    /*bookingDateRequests() {
      try {
        if (this.bookingDateRequests && Array.isArray(this.bookingDateRequests) && this.bookingDateRequests.length) {
          return this.selectBookingDateRequest(this.bookingDateRequests[this.bookingDateRequests.length - 1]);
        }
        this.addBookingDateRequest(this.bookingTicketVoucher?.Id);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },*/
    voucher() {
      try {
        if (!this.voucher?.Id) {
          return;
        }
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
