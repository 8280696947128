const {
  BookingDateRequests,
} = require("@/graphql/bookingDateRequests/BookingDateRequests.ts");

class BookingDateRequestsComponent {
  async getBookingDateRequests(
    apollo,
    bookingTicketId,
    bookingTicketVoucherId
  ) {
    try {
      if (!apollo || (!bookingTicketId && !bookingTicketVoucherId)) {
        return undefined;
      }
      const bookingDateRequests = await apollo
        .query({
          query: BookingDateRequests.Queries.BookingDateRequests,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicketId,
            bookingTicketVoucherId: bookingTicketVoucherId,
          },
        })
        .then(({ data }) => data?.bookingDateRequests)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!bookingDateRequests) {
        return undefined;
      }
      return bookingDateRequests;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async deleteBookingDateRequest(apollo, id) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!id) {
        return {
          IsSuccess: false,
          Message: "Keine Id gefunden.",
        };
      }
      const deletedBookingDateRequest = await apollo
        .mutate({
          mutation: BookingDateRequests.Mutations.DeleteBookingDateRequest,
          fetchPolicy: "no-cache",
          variables: {
            id: id,
          },
        })
        .then(({ data }) => data?.deleteBookingDateRequest)
        .catch((e) => {
          console.error(e);
          return {
            IsSuccess: false,
            Message: e.message,
          };
        });
      return deletedBookingDateRequest;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}

module.exports = BookingDateRequestsComponent;
