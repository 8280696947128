const {
  FellowTravelers,
} = require("@/graphql/fellowTravelers/FellowTravelers.ts");

class FellowTravelersComponent {
  async getFellowTravelers(apollo, bookingTicketId, bookingTicketVoucherId) {
    try {
      if (!apollo || (!bookingTicketId && !bookingTicketVoucherId)) {
        return undefined;
      }
      const fellowTravelers = await apollo
        .query({
          query: FellowTravelers.Queries.FellowTravelers,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicketId,
            bookingTicketVoucherId: bookingTicketVoucherId,
          },
        })
        .then(({ data }) => data?.fellowTravelers)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return fellowTravelers;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = FellowTravelersComponent;
