import gql from "graphql-tag";

const _props = `
    ExclusionDays
    ExclusionSpans {
        from
        to
    }
    ExclusionWeekDays
    ExclusionPeriods
`;

export const ExclusionPeriods = {
    Queries: {
        ExclusionPeriods: gql`
        query exclusionPeriods($bookingTicketId: ID, $from: Date, $to: Date, $bookingTicketVoucherId: ID) {
            exclusionPeriods(bookingTicketId: $bookingTicketId, from: $from, to: $to, bookingTicketVoucherId: $bookingTicketVoucherId) {
                ${_props}
            }
        }`,
    },
};
