const { Hosts } = require("@/graphql/hosts/Hosts.ts");

class HostsComponent {
  async getHost(apollo, hostId, voucherId) {
    try {
      if (!apollo || (!hostId && !voucherId)) {
        return undefined;
      }
      const host = await apollo
        .query({
          query: Hosts.Queries.Host,
          variables: {
            id: hostId,
            voucherId: voucherId,
          },
        })
        .then(({ data }) => data?.host)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return host;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getHosts(apollo, bookingTicketId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!bookingTicketId) {
        return undefined;
      }
      const hosts = await apollo
        .query({
          query: Hosts.Queries.Hosts,
          variables: {
            bookingTicketId: bookingTicketId,
          },
        })
        .then(({ data }) => data?.hosts)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return hosts;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getHostsByVouchers(apollo, email, voucherStatusIds, validTill) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!email && !voucherStatusIds && !validTill) {
        return undefined;
      }
      const hosts = await apollo
        .query({
          query: Hosts.Queries.HostsByVouchers,
          variables: {
            email: email,
            voucherStatusIds: voucherStatusIds,
            validTill: validTill,
          },
        })
        .then(({ data }) => data?.hostsByVouchers)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      return hosts;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = HostsComponent;
