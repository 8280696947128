const EmailValidatorComponent = require("@/components/emailValidator/EmailValidatorComponent");
const { MolossUsers } = require("@/graphql/molossUsers/MolossUsers.ts");
const { Buffer } = require("buffer");

class MolossUsersComponent {
  async loginMolossUser(apollo, email, password) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!email) {
        return {
          IsSuccess: false,
          Message: "Keine E-Mail Adresse gefunden.",
        };
      }
      email = email.toLowerCase().trim();
      const emailValidatorComponent = new EmailValidatorComponent();
      const isEmailValid = emailValidatorComponent.validateEmail(email);
      if (!isEmailValid) {
        return {
          IsSuccess: false,
          Message: "Die eingegebene E-Mail Adresse ist nicht gültig.",
        };
      }
      if (!password) {
        return {
          IsSuccess: false,
          Message: "Kein Passwort gefunden.",
        };
      }
      const buffer = new Buffer(password);
      const loggedInMolossUser = await apollo
        .mutate({
          mutation: MolossUsers.Mutations.LoginMolossUser,
          fetchPolicy: "no-cache",
          variables: {
            email: email,
            password: buffer.toString("base64"),
          },
        })
        .then(({ data }) => data?.loginMolossUser)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!loggedInMolossUser) {
        return {
          IsSuccess: false,
          Message:
            "Der Benutzer konnte nicht eingeloggt werden. Versuchen Sie es später erneut oder kontaktieren Sie den touriDat-Support.",
        };
      }
      return loggedInMolossUser;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}

module.exports = MolossUsersComponent;
