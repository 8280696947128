<template lang="pug">
.modal-wrap.p-3
  .modal-close-wrap
    i.modal-close-button(@click="close")
      font-awesome-icon(:icon="['fas', 'times']")

  .container-fluid#help-modal
    .row
      .col-12
        .modal-headline.pb-2.mb-3.border-bottom {{ $t("report-error-modal.headline") }}
      .col-12
        textarea-autosize.form-control(:placeholder="this.$t('report-error-modal.placeholder')" v-model="notification.Message" :disabled="!userGroup")
      .col-12.mt-2
        .float-right
          button.button.button-primary.button-tdays(@click="saveNotification" :disabled="!userGroup" :class="{ 'disabled': !userGroup }") {{ this.$t('report-error-modal.report') }}

</template>

<script>
import EventBus from "@/event-bus";
import NotificationsComponent from "@/components/notifications/NotificationsComponent";
import UserGroupsComponent from "@/components/userGroups/UserGroupsComponent";

export default {
  name: "help-modal",
  props: {
    voucherId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notification: {
        Title: `${this.voucherId}: Neuer touriBook Error-Report`,
        Message: "",
      },
      userGroup: undefined,
    };
  },
  async mounted() {
    try {
      this.loadUserGroup();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadUserGroup() {
      try {
        EventBus.$emit("changeLoadingState", true);
        const userGroupsComponent = new UserGroupsComponent();
        const userGroup = await userGroupsComponent.getUserGroupByIndicator(
          this.$apollo,
          "TECHNIK"
        );
        EventBus.$emit("changeLoadingState", false);
        if (!userGroup) {
          return;
        }
        this.userGroup = userGroup;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveNotification() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie den Fehler melden möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const notificationsComponent = new NotificationsComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedNotification = await notificationsComponent.saveNotification(
          this.$apollo,
          this.notification
        );
        EventBus.$emit("changeLoadingState", false);
        if (!savedNotification || !savedNotification.IsSuccess) {
          return this.$alert(
            "Der Fehler konnte nicht gemeldet werden, bitte versuchen Sie es später erneut."
          );
        } else {
          this.$alert(
            "Der Fehler wurde erfolgreich gemeldet, das Techik-Team kümmerst sich schnellstmöglich darum."
          );
        }
        this.close();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
#help-modal ul {
  padding-left: 0;
  list-style-type: none;
}

#help-modal ul li {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 8px;
}

#help-modal ul li:hover {
  color: #488345;
}
</style>
