import Login from "@/views/login/Login.vue";
import Home from "@/views/Home.vue";
import BookingTicketDetails from "@/views/bookingTickets/BookingTicketDetails.vue";
import NotFound from "@/views/pages/NotFound.vue";

export default [
  {
    path: "/login",
    component: Login,
    name: Login.name,
    meta: {
      isLogin: true,
    },
  },
  {
    path: "/book",
    alias: "/",
    component: Home,
    name: Home.name,
  },
  {
    path: "/booking/:bookingTicketGuid",
    component: BookingTicketDetails,
    name: BookingTicketDetails.name,
    props: true,
  },
  {
    path: "/booking/new",
    component: BookingTicketDetails,
    name: "BookingTicketNew",
    props: true,
  },
  {
    path: "/*",
    component: NotFound,
    name: NotFound.name,
    meta: {
      PlainTemplate: true,
    },
  },
];
