<template lang="pug">
.datepicker
  td-loading-overlay(
    v-if="isLoading"
    position="absolute"
  )
  datepicker.p-0(
    :disabled="isDisabled"
    :inline="true"
    :bootstrap-styling="true"
    :language="lang"
    :monday-first="true"
    :disabled-dates="disabledSpans"
    maximum-view="month"
    :full-month-name="true"
    :highlighted="highlighted"
    @selected="_selectDate($event)"
    @changedMonth="changeDate($event)"
    @changedYear="changeDate($event)"
    @changedDecade="changeDate($event)"
  )
  .row(v-if="bookingTicket.BookingTicketTypeId != BookingTicketTypesEnum.DifferentArrivalDays")
    .col-12.mt-3(v-if="exclusionPeriods && exclusionPeriods.ExclusionPeriods && Array.isArray(exclusionPeriods.ExclusionPeriods) && exclusionPeriods.ExclusionPeriods.length")
      .row
        .col-12
          h5 Ausschlusszeiten:
      .row
        .col-12
          ul
            li(v-for="exclusionPeriod in exclusionPeriods.ExclusionPeriods") {{ exclusionPeriod.Name }} ({{ exclusionPeriod.FromDate | formatDate }} - {{ exclusionPeriod.ToDate | formatDate }})

</template>

<script>
import Datepicker from "vuejs-datepicker";
import TdLoadingOverlay from "../../partials/loading/td-loading-overlay.vue";
import EventBus from "../../../event-bus";
import { de, en } from "vuejs-datepicker/dist/locale";
import ExclusionPeriodsComponent from "@/components/exclusionPeriods/ExclusionPeriodsComponent";
import moment from "moment/moment";
import { BookingTicketTypesEnum } from "@/utils/enums/bookingTicketTypes/BookingTicketTypesEnum.ts";

export default {
  name: "BookingDatePicker",
  components: {
    TdLoadingOverlay,
    Datepicker,
  },
  props: {
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingTicketVoucher: {
      type: Object,
      required: false,
    },
    bookingDateRequests: {
      type: Array,
      required: false,
    },
    nights: {
      type: Array,
      required: false,
    },
    selectDate: {
      type: Function,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      exclusionPeriods: undefined,
      highlighted: undefined,
      currentDate: moment().startOf("month").toDate(),
      lang: localStorage.localeSettings == "en" ? en : de,
      isLoading: false,
      BookingTicketTypesEnum: BookingTicketTypesEnum,
    };
  },
  async mounted() {
    try {
      EventBus.$on(
        "changeLanguage",
        function (language) {
          this.lang = language == "en" ? en : de;
        }.bind(this),
      );
      EventBus.$on(
        "changeCalendarLoading",
        function (isLoading) {
          this.isLoading = isLoading;
        }.bind(this),
      );
      EventBus.$on(
        "updateCalendarHighlights",
        function (bookingTicketVoucher) {
          if (
            bookingTicketVoucher &&
            bookingTicketVoucher != this.bookingTicketVoucher
          ) {
            return;
          }
          this.highlighted = this.getHighlighted();
        }.bind(this),
      );
      this.highlighted = this.getHighlighted();
      this.loadExclusionPeriods();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    _selectDate(date) {
      try {
        this.selectDate(date, this.bookingTicketVoucher);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadExclusionPeriods() {
      try {
        if (!this.bookingTicket) {
          return;
        }
        const exclusionPeriodsComponent = new ExclusionPeriodsComponent();
        this.isLoading = true;
        const exclusionPeriods =
          await exclusionPeriodsComponent.getExclusionPeriods(
            this.$apollo,
            this.bookingTicket?.Id,
            this.from,
            this.to,
            this.bookingTicketVoucher?.Id,
          );
        this.isLoading = false;
        if (!exclusionPeriods) {
          return;
        }
        this.exclusionPeriods = exclusionPeriods;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    changeDate(date) {
      try {
        if (date.year) {
          return;
        } else if (date.month) {
          this.currentDate = moment().month(date.month).toDate();
        } else if (moment(date).isValid()) {
          this.currentDate = date;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    getDisabledSpans() {
      try {
        if (!this.exclusionPeriods || this.isDisabled) {
          return {
            ranges: [
              {
                from: moment().subtract(100, "years").toDate(),
                to: moment().add(100, "years").toDate(),
              },
            ],
          };
        }
        const exclusionDays = [];
        for (const exclusionDay of this.exclusionPeriods.ExclusionDays) {
          exclusionDays.push(moment(exclusionDay).toDate());
        }
        if (this.exclusionPeriods.ExclusionSpans) {
          this.exclusionPeriods.ExclusionSpans.forEach((e) => {
            e.from = new Date(e.from);
            e.to = new Date(e.to);
          });
        }
        let bookingDateRequests = [];
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          bookingDateRequests = this.bookingDateRequests.filter(
            (bookingDateRequest) =>
              bookingDateRequest.BookingTicketVoucherId ==
              this.bookingTicketVoucher.Id,
          );
        } else {
          bookingDateRequests = this.bookingDateRequests.filter(
            (bookingDateRequest) => !bookingDateRequest.BookingTicketVoucherId,
          );
        }
        bookingDateRequests.forEach((bookingDateRequest) => {
          if (!bookingDateRequest.Date) {
            return;
          }
          exclusionDays.push(moment(bookingDateRequest.Date).toDate());
        });
        return {
          ranges: this.exclusionPeriods.ExclusionSpans,
          days: this.exclusionPeriods.ExclusionWeekDays,
          dates: exclusionDays,
        };
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    getHighlighted() {
      try {
        const dates = [];
        if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.SameArrivalDay
        ) {
          for (const bookingDateRequest of this.bookingDateRequests) {
            if (!bookingDateRequest.Date) {
              continue;
            }
            for (const night of this.nights) {
              for (let i = 0; i <= night; i++) {
                dates.push(
                  moment(bookingDateRequest.Date).add(i, "days").toDate(),
                );
              }
            }
          }
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.CombineTravelPeriod
        ) {
          if (!this.bookingDateRequests) {
            return undefined;
          }
          for (const bookingDateRequest of this.bookingDateRequests) {
            const diff = moment(bookingDateRequest.ToDate).diff(
              bookingDateRequest.FromDate,
              "days",
            );
            if (diff == undefined) {
              return undefined;
            }
            for (let i = 0; i <= diff; i++) {
              dates.push(
                moment(bookingDateRequest.FromDate).add(i, "days").toDate(),
              );
            }
          }
        } else if (
          this.bookingTicket.BookingTicketTypeId ==
          BookingTicketTypesEnum.DifferentArrivalDays
        ) {
          const bookingDateRequests = this.bookingDateRequests.filter(
            (bookingDateRequest) =>
              bookingDateRequest.BookingTicketVoucherId ==
              this.bookingTicketVoucher.Id,
          );
          if (!bookingDateRequests) {
            return;
          }
          for (const bookingDateRequest of bookingDateRequests) {
            const diff = moment(bookingDateRequest.ToDate).diff(
              bookingDateRequest.FromDate,
              "days",
            );
            if (diff == undefined) {
              return;
            }
            for (let i = 0; i <= diff; i++) {
              dates.push(
                moment(bookingDateRequest.FromDate).add(i, "days").toDate(),
              );
            }
          }
        }
        return {
          dates: dates,
          includeDisabled: true,
        };
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    from() {
      try {
        return moment(this.currentDate).startOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    to() {
      try {
        return moment(this.currentDate).endOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    disabledSpans() {
      try {
        return this.getDisabledSpans();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  watch: {
    "bookingTicket.Id"() {
      try {
        this.loadExclusionPeriods();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    "bookingTicketVoucher.Id"() {
      try {
        this.loadExclusionPeriods();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    currentDate() {
      try {
        this.loadExclusionPeriods();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    bookingDateRequests() {
      try {
        this.highlighted = this.getHighlighted();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
