<template lang="pug">
.home
  .row
    .col-12
      booking-tickets.mt-2

</template>

<script>
import BookingTickets from "@/views/bookingTickets/BookingTickets.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: { BookingTickets },
};
</script>

<style scoped></style>
