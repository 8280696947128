import Vue from "vue";
import VueRouter from "vue-router";
import "bootstrap";
import App from "./App.vue";
import routes from "./utils/routes.utils";
import i18n from "./utils/i18n.utils";
import { vuePluginsUtils } from "@/utils/vue-plugins.utils";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueApollo from "vue-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import TextareaAutosize from "vue-textarea-autosize";

import VueSession from "vue-session";

import VueLogger from "vuejs-logger";

const appMode = process.env.VUE_APP_MODE;

const options = {
  isEnabled: true,
  logLevel: appMode === "production" ? "warn" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);
Vue.use(VueSession, {
  persist: true,
});

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;
// config
const Config = require("./config/config"),
  conf = Config();

// Vue.$log.warn(conf);
// utils
for (const util of vuePluginsUtils) {
  Vue.use(util.plugin, util.config);
}

Vue.use(TextareaAutosize);
Vue.use(VueRouter);
const router = new VueRouter({
  //duplicateNavigationPolicy: "reload",
  mode: "history",
  base: __dirname,
  routes: routes,
});

const moment = require("moment");
router.beforeEach((to, from, next) => {
  if (to.name == "Login") {
    return next();
  }
  // needs to login
  if (
    !router.app.$session.exists() ||
    moment(router.app.$session.get("validTill")).isBefore(new Date())
  ) {
    router.app.$session.destroy();
    return next({
      name: "Login",
      query: { redirect: to.path },
    });
  } else {
    router.app.$session.set(
      "validTill",
      moment(new Date()).add(12, "hours").toDate(),
    );
    next();
  }
});

Vue.use(VueApollo);
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: conf.graphQl.uri,
});

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

import Utils from "@/utils";

Vue.filter(
  Utils.vueFilter.formatDateWithDayFilter.name,
  Utils.vueFilter.formatDateWithDayFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatInputDateFilter.name,
  Utils.vueFilter.formatInputDateFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatInputDateTimeFilter.name,
  Utils.vueFilter.formatInputDateTimeFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatDateFilter.name,
  Utils.vueFilter.formatDateFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatValueFilter.name,
  Utils.vueFilter.formatValueFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatEuroFilter.name,
  Utils.vueFilter.formatEuroFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatPercentageFilter.name,
  Utils.vueFilter.formatPercentageFilter.func,
);
Vue.filter(
  Utils.vueFilter.formatDateTimeFilter.name,
  Utils.vueFilter.formatDateTimeFilter.func,
);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  apolloProvider,
  i18n,
  render: (h) => h(App),
  created() {
    const locale = localStorage.getItem("localeSettings");
    if (locale) {
      this.$i18n.locale = locale;
    } else if (navigator.language) {
      this.$i18n.locale = navigator.language.substring(0, 2);
    }

    this.$log.warn(
      `started App in ${appMode} - Mode... waiting for interaction`,
    );
  },
});
