const { HyperGuest } = require("../../graphql/hyperGuest/HyperGuest.ts");

class HyperGuestComponent {
  async getRate(
    apollo,
    bookingDate,
    bookingTicketId,
    bookingTicketVoucherId = undefined
  ) {
    try {
      if (!apollo || !bookingDate || !bookingTicketId) {
        return undefined;
      }
      const rate = await apollo
        .query({
          query: HyperGuest.Queries.Rate,
          fetchPolicy: "no-cache",
          variables: {
            bookingDate: bookingDate,
            bookingTicketId: bookingTicketId,
            bookingTicketVoucherId: bookingTicketVoucherId,
          },
        })
        .then(({ data }) => data?.rate)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!rate) {
        return undefined;
      }
      return rate;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = HyperGuestComponent;
