<template lang="pug">
.voucher(v-if="localVoucher")
  .row.mb-2(v-if="showHost && localHost")
    .col-12
      span.font-weight-bold {{ localHost.Match1 }}
  .row
    .col-12.d-flex
      .d-flex.align-items-center
        span {{ localVoucher.Id }}: {{ localVoucher.Arrangement }}

  template(v-if="!isPreview")
    .row
      .col-12
        hr
    .row.mt-2(v-if="holidayServices && Array.isArray(holidayServices) && holidayServices.length")
      .col-12
        span {{ $t("customer-data.services") }}
        ul
          li(v-for="holidayService in holidayServices" v-if="holidayService.trim()") {{ holidayService.trim() }}
    .row(v-if="additionalHolidayServices && Array.isArray(additionalHolidayServices) && additionalHolidayServices.length")
      .col-12
        span(style="font-style: italic;") {{ $t("customer-data.additionalServices") }}
        ul
          li(v-for="additionalHolidayService in additionalHolidayServices" v-if="additionalHolidayService.trim()") {{ additionalHolidayService.trim() }}
    template(v-if="localHost && localHost.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook")
      .row
        .col-12
          span {{ $t("customer-data.bookability") }}
          p {{ localVoucher.BookingAvailability }}
      .row
        .col-12.mt-2
          span {{ $t("customer-data.section-headline3") }}
          p {{ $t("customer-data.section-text3") }}

</template>

<script>
import ProductsComponent from "@/components/products/ProductsComponent";
import HostImagesComponent from "@/components/hostImages/HostImagesComponent";
import { ImageTypesEnum } from "@/utils/enums/imageTypes/ImageTypesEnum.ts";
import HostsComponent from "@/components/hosts/HostsComponent";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import VouchersComponent from "@/components/vouchers/VouchersComponent";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Voucher",
  props: {
    voucherId: {
      type: [String, Number],
      required: false,
    },
    voucher: {
      type: Object,
      required: false,
    },
    host: {
      type: Object,
      required: false,
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
    showHost: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localVoucher: this.voucher,
      product: undefined,
      localHost: this.host,
      hostImages: [],
      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  async mounted() {
    try {
      if (this.voucherId) {
        this.loadVoucher();
      }
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVoucher() {
      try {
        if (!this.voucherId) {
          return;
        }
        const vouchersComponent = new VouchersComponent();
        const voucher = await vouchersComponent.getVoucher(
          this.$apollo,
          this.voucherId,
        );
        if (!voucher) {
          return;
        }
        this.localVoucher = voucher;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        if (!this.localVoucher?.ProductId) {
          return;
        }
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.localVoucher?.ProductId,
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHost() {
      try {
        if (!this.localVoucher?.AddressId) {
          return;
        }
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(
          this.$apollo,
          this.localVoucher?.AddressId,
        );
        if (!host) {
          return;
        }
        this.localHost = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHostImages() {
      try {
        if (!this.localVoucher?.HostId) {
          return;
        }
        const hostImagesComponent = new HostImagesComponent();
        const hostImages = await hostImagesComponent.getHostImages(
          this.$apollo,
          this.localVoucher?.AddressId,
          [ImageTypesEnum.ArticleImage],
        );
        if (!hostImages) {
          return;
        }
        this.hostImages = hostImages;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    localVoucher() {
      try {
        if (!this.localVoucher?.Id) {
          return;
        }
        this.loadProduct();
        this.loadHostImages();
        if (!this.host) {
          this.loadHost();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    imageUrl() {
      try {
        const articleImage = this.hostImages.find((hostImage) => hostImage);
        if (!articleImage) {
          return "https://s3-cloud.td-cdn.de/ImageManagementUploaded/14700/-aussenansicht-am-abend-14700_medium.jpg";
        }
        return articleImage.RemoteBaseUrl + "medium.jpg";
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    holidayServices() {
      try {
        if (!this.localVoucher || !this.localVoucher.HolidayServices) {
          return undefined;
        }
        return this.localVoucher.HolidayServices.split("\n");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    additionalHolidayServices() {
      try {
        if (
          !this.localVoucher ||
          !this.localVoucher.AdditionalHolidayServices
        ) {
          return undefined;
        }
        return this.localVoucher.AdditionalHolidayServices.split("\n");
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
