import gql from "graphql-tag";

const _props = `
    Id
    VoucherId
    BookingTicketId
    BookingTicketVoucherStatusId
    CreatedAt
    FromDate
    ToDate
`;

export const BookingTicketVouchers = {
    Queries: {
        BookingTicketVouchers: gql`
        query bookingTicketVouchers($bookingTicketId: ID, $hostIds: [ID]) {
            bookingTicketVouchers(bookingTicketId: $bookingTicketId, hostIds: $hostIds) {
                ${_props}
            }
        }`,
    },
    Mutations: {
        DeleteBookingTicketVoucher: gql`
        mutation deleteBookingTicketVoucher($id: ID) {
            deleteBookingTicketVoucher(id: $id) {
                IsSuccess
                Message
            }
        }`,
    },
};
